import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import pagePath from "../../util/constants/pagePath";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import { useLocation } from "react-router-dom";
import { faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion"; // Import Framer Motion

const Evolve = () => {
  const [percentageValue, setPercentageValue] = useState(50);
  const [userRating, setUserRating] = useState(0);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const navigate = useNavigate();
  const [volume, setVolume] = useState(50);
  const location = useLocation();
  const todayPredictionID = location?.state?.todayPredictionID;
  const [isSaving, setIsSaving] = useState(false);
  const volumeSliderRef = useRef(null);

  const handleHomeButtonClick = () => {
    navigate(pagePath.home);
  };

  if (!todayPredictionID) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
        <button
          onClick={handleHomeButtonClick}
          className="text-red-500 text-2xl"
        >
          &#8592;
        </button>
        <h1 className="text-red-500 text-4xl">
          <i className="fas fa-home"></i>
        </h1>
        <p className="text-gray-600 text-lg mt-4">No predictions found</p>
      </div>
    );
  }

  const FeedbackAlreadyGivenMessage = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white p-6 rounded-md shadow-md text-center">
        <p className="text-green-500 text-2xl mb-4">
          ✔ Feedback has already been given
        </p>
      </div>
    </motion.div>
  );

  useEffect(() => {
    setIsButtonActive(selectedEmoji !== null);
  }, [selectedEmoji]);

  const handleEmojiClick = (rating) => {
    setUserRating(rating);
    setSelectedEmoji(rating);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    const percentage = Math.round((newVolume / 100) * 100);
    setPercentageValue(percentage);
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleEvolve = async () => {
    setIsSaving(true);

    const feedbackData = {
      predictionId: todayPredictionID,
      userRating: userRating,
      accuracyPercentage: percentageValue / 100,
      userFeedback: briefText,
    };

    try {
      await standardBizApiClient.evolve_user_feedback(
        feedbackData.predictionId,
        feedbackData.userRating,
        feedbackData.accuracyPercentage,
        feedbackData.userFeedback
      );

      setSuccessMessage("Feedback submitted successfully");
      setTimeout(() => {
        setSuccessMessage(null);
        navigate(pagePath.dashboard);
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const [briefText, setBriefText] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const handleBriefTextChange = (event) => {
    setBriefText(event.target.value);
  };

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await standardBizApiClient.get_user_feedback();
        const feedbackData = response.evolve_result;
        const feedbackExistsForTodayPrediction = feedbackData.some(
          (feedback) => {
            const evolveData = feedback.evolve;
            return evolveData && evolveData.prediction_id === todayPredictionID;
          }
        );
        setFeedbackGiven(feedbackExistsForTodayPrediction);
      } catch (error) {
        console.error("Error retrieving feedback:", error);
      }
    };

    fetchFeedback();
  }, [todayPredictionID]);

  const [showFeedbackAlreadyGiven, setShowFeedbackAlreadyGiven] =
    useState(false);
  useEffect(() => {
    if (feedbackGiven) {
      setShowFeedbackAlreadyGiven(true);
      setTimeout(() => {
        setShowFeedbackAlreadyGiven(false);
        navigate(pagePath.dashboard);
        window.location.reload();
      }, 3000);
    }
  }, [feedbackGiven]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        className="flex flex-col items-center justify-center bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] min-h-screen relative"
      >
        <img
          src="/dst-logo.svg"
          alt="Destini Logo"
          className="w-28 h-28 max-sm:mt-[-115px]"
        />
        {/* Back arrow positioned at the top-left */}
        <span
          onClick={() => navigate(pagePath.home)}
          className="absolute top-4 left-4 text-3xl text-white font-extrabold cursor-pointer"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>

        {/* Center the entire container */}
        <motion.div
          className="evolve p-6 bg-white shadow-md rounded-lg mb-6 max-w-md mx-auto max-sm:p-8 max-sm:text-lg"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="mb-6">
            <h4 className="text-lg font-rale font-semibold mb-4 text-gray-700 text-center max-sm:text-xl">
              How was today's prediction?
            </h4>
            <div className="flex justify-center space-x-4">
              {["😢", "😟", "😐", "😊", "😁"].map((emoji, index) => (
                <motion.span
                  key={index}
                  role="img"
                  aria-label={emoji}
                  className={`cursor-pointer text-3xl max-sm:text-4xl ${
                    selectedEmoji === index + 1
                      ? "text-yellow-400 scale-125 border-4 border-[#FF9F3A] rounded-full"
                      : "text-gray-400"
                  }`}
                  onClick={() => handleEmojiClick(index + 1)}
                  style={{
                    filter:
                      selectedEmoji && selectedEmoji !== index + 1
                        ? "blur(2px)"
                        : "none",
                  }}
                  whileHover={{
                    scale: selectedEmoji === index + 1 ? 1.3 : 1.1,
                    rotate: 10,
                  }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  {emoji}
                </motion.span>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h4 className="text-lg font-rale font-semibold mb-4 text-gray-700 text-center max-sm:text-xl">
              How accurate was it?
            </h4>
            <div className="flex items-center space-x-4">
              <motion.input
                type="range"
                min="0"
                max="100"
                value={volume}
                onChange={handleVolumeChange}
                className="w-full h-2 bg-purple-500 text-purple-500 rounded-lg max-sm:h-3"
                ref={volumeSliderRef}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <motion.div
                className="text-blue-600 font-bold max-sm:text-xl"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                {percentageValue}%
              </motion.div>
            </div>
          </div>

          <div className="mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-700 font-rale text-center max-sm:text-xl">
              Detailed Feedback
            </h4>
            <textarea
              className="w-full p-4 border border-gray-300 rounded-lg max-sm:p-6 max-sm:text-lg"
              rows="3"
              value={briefText}
              onChange={handleBriefTextChange}
              placeholder="Write your feedback here... or reach out to feedback@destini.ai"
            />
          </div>

          <motion.button
            className={`w-full py-2 rounded-lg text-white ${
              selectedEmoji ? "bg-[#FF9F3A]" : "bg-gray-400"
            } transition duration-300 max-sm:py-3 max-sm:text-lg`}
            onClick={handleEvolve}
            disabled={!selectedEmoji}
            whileHover={{ scale: selectedEmoji ? 1.05 : 1 }}
            whileTap={{ scale: 0.95 }}
          >
            {isSaving ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Feedback for Making Destini better"
            )}
          </motion.button>
        </motion.div>
      </motion.div>

      {successMessage && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <p className="text-green-500 text-2xl mb-4">✔ {successMessage}</p>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Evolve;
