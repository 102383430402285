import React from "react";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import getCssVariable from "../../util/functions/getCssVariable";

const LoadingButton = ({ variant = "contained", fontSize = "16px", customProps, children }) => {
    const primaryColorDark = getCssVariable("--dst-color-primary-dark");
    const primaryColor = getCssVariable("--dst-color-primary");
    const fontFamily = getCssVariable("--dst-font-body-regular");

    let StyledMuiLoadingButton;
    if (variant === "contained") {
        StyledMuiLoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
            color: "#fff",
            backgroundColor: primaryColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                backgroundColor: primaryColorDark,
            },
            "& .MuiLoadingButton-loadingIndicator": {
                position: "initial",
                marginRight: "8px"
            }
        }));
    }
    if (variant === "text") {
        StyledMuiLoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
            color: primaryColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "& .MuiLoadingButton-loadingIndicator": {
                position: "initial",
                marginRight: "8px"
            }
        }));
    }
    if (variant === "outlined") {
        StyledMuiLoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
            color: primaryColor,
            backgroundColor: "#fff",
            borderColor: primaryColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                color: primaryColorDark,
                borderColor: primaryColorDark,
            },
        }));
    }

    return (
        <StyledMuiLoadingButton
            variant={variant}
            loadingPosition="start"
            startIcon={null} // text is disappearing on loading when loadingPosition is not declared, need to look into it. For now declaring it even though it is unused
            {...customProps}
        >
            {children}
        </StyledMuiLoadingButton>
    )
}

export default LoadingButton;