import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import pagePath from "../../util/constants/pagePath.js";

const Home = () => {
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = React.useState(null);
  const { platform, os } = useSelector((state) => state.device_context);
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const handleNavigation = (path, button) => {
    setLoadingButton(button);
    setTimeout(() => {
      navigate(path);
      setLoadingButton(null);
    }, 1000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    const timer = setTimeout(() => {
      setIsSplashVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const spinnerVariants = {
    animate: {
      rotate: [0, 360],
      transition: {
        repeat: Infinity,
        ease: "linear",
        duration: 1,
      },
    },
  };

  if (isSplashVisible) {
    return (
      <motion.div
        className="min-h-screen flex items-center justify-center bg-gray-50 fixed inset-0 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {isMobile ? (
          <img
            src="/splash-m.svg"
            alt="Splash Logo Mobile"
            className="w-full h-full object-cover"
          />
        ) : (
          <img
            src="/splash-d.svg"
            alt="Splash Logo"
            className="w-full h-full object-cover"
          />
        )}
      </motion.div>
    );
  }

  return (
    <motion.div
      className="bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] min-h-screen flex flex-col justify-center items-center relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <motion.div
        className="absolute top-4 left-4 max-sm:static max-sm:flex max-sm:justify-center max-sm:mb-16 max-sm:w-full"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
      >
        <img
          src="/dst-logo.svg"
          alt="Logo"
          className="w-28 h-28 max-sm:w-40 max-sm:h-40"
        />
      </motion.div>

      <motion.div
        className="text-center mb-4"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1.2, ease: "easeInOut" }}
      >
        <img src="/chakra.svg" alt="Chakra" className="w-80 mx-auto mb-6" />
      </motion.div>

      <motion.div
        className="flex flex-col items-center space-y-4"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut", delay: 0.3 }}
      >
        <motion.button
          onClick={() => handleNavigation(pagePath.signup, "signup")}
          disabled={loadingButton === "login"}
          className={`w-60 bg-[#FF9F3A] font-rale hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-md transition-all ${
            loadingButton === "signup" ? "opacity-50 cursor-not-allowed" : ""
          }`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          {loadingButton === "signup" ? (
            <motion.div
              className="w-5 h-5 border-2 border-t-transparent border-white rounded-full mx-auto"
              variants={spinnerVariants}
              animate="animate"
            ></motion.div>
          ) : (
            "Register"
          )}
        </motion.button>

        <motion.button
          onClick={() => handleNavigation(pagePath.loginV2, "login")}
          disabled={loadingButton === "signup"}
          className={`w-60 bg-[#D9D9D9] font-rale hover:bg-gray-400 text-[#FF9F3A] font-bold py-2 px-4 rounded-md transition-all ${
            loadingButton === "login" ? "opacity-50 cursor-not-allowed" : ""
          }`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          {loadingButton === "login" ? (
            <motion.div
              className="w-5 h-5 border-2 border-t-transparent border-[#FF9F3A] rounded-full mx-auto"
              variants={spinnerVariants}
              animate="animate"
            ></motion.div>
          ) : (
            "Log In"
          )}
        </motion.button>
      </motion.div>

      <motion.div
        className="mt-4 text-gray-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, delay: 1 }}
      >
        <p className="font-rale text-[15px]">
          Terms & Conditions |{" "}
          <span
            onClick={() => window.open(pagePath.privacypolicy, "_blank")}
            className=" cursor-pointer hover:underline"
          >
            Privacy Policy
          </span>
        </p>
      </motion.div>
    </motion.div>
  );
};

export default Home;
