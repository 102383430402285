import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useHistory } from "react-router-dom";
import pagePath from "./util/constants/pagePath";

import Layout from "./components/layout/Layout";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import SupportLogin from "./pages/support/SupportLogin";
import Signup from "./pages/Signup/Signup";
import SignupConfirm from "./pages/signupConfirm/SignupConfirm";
import ResetPasswordInitiate from "./pages/resetPasswordInitiate/ResetPasswordInitiate";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import AboutUs from "./pages/aboutus/AboutUs";
import Profile from "./pages/profile/Profile";
import Account from "./pages/account/Account";
import TimeoutWarning from "./components/timeoutWarning/TimeoutWarning";
import Dashboard from "./pages/dashboard/Dashboard";
import Signup1 from "./pages/OTP signup/OTP signup";
import Success_Signup from "./pages/OTP signup/Success_signup";
import LoginV2 from "./pages/login2.0/Login2.0"
import UserDetails from "./pages/userDetails/UserDetails"
import LandingPage from "./pages/landing_page/LandingPage"
import Past from "./pages/past/Past";
import Evolve from "./pages/evolve/Evolve";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import ContactUs from "./pages/contactUs/ContactUs";
import addNotification from "react-push-notification";
import logo from "./logo/android-chrome-192x192.png";
import useDeviceContextUpdater from "./hooks/useDeviceContextUpdater";
import WhileBrowserRedirect from "./pages/mobileAppAction/WhileBrowserRedirect";
import ProgressPage from "./components/progressPage/ProgressPage";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";

// import VedicAstro from "./pages/vedicastro/vedicastro";
// import ApiReport from "./pages/apireport/apireport";

const scheduleNextNotification = () => {
  // Calculate the time until 9 AM
  const now = new Date();
  let millisTill9 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 0, 0, 0) - now;
  if (millisTill9 < 0) {
    // If it's already past 9 AM, move to tomorrow
    millisTill9 += 86400000; // 24 hours in milliseconds
  }

  // Schedule notification to trigger after millisTill9 milliseconds
  setTimeout(() => {
    displayNotification();
    // Schedule the next notification
    scheduleNextNotification();
  }, millisTill9);
};

const displayNotification = () => {
  console.log("call in notification");
  addNotification({
    title: "Destini.ai",
    message: "Knock Knock!!! Review your Today's Destini.",
    theme: "darkblue",
    onClick: () => {
      // Navigate to "/evolve" route when notification is clicked
      window.location.href = "/evolve";
    },
    native: true, // Use native browser notifications if available
  });

  const audio = new Audio("notification.mp3"); 
  audio.play().catch(error => {
        console.error("Failed to play audio:", error);
      });

  // Close the notification after 6 seconds
  setTimeout(() => {
    addNotification.close();
  }, 6000);
};
const App = () => {
  useDeviceContextUpdater();
  const signedIn = useSelector((state) => state.user.signedIn);

  useEffect(() => {
    // Schedule the first notification when the component mounts
    scheduleNextNotification();

    // Cleanup function to clear any remaining timeouts on unmount
    return () => clearTimeout();
  }, []);

  // support login is separate because it handles the session differently
  // when there is already a session on the support login page, it waits for signout before signing in
  if (window.location.pathname.startsWith(pagePath.support_login)) {
    return (
        <Routes>
          <Route
            exact
            path={pagePath.support_login}
            element={<SupportLogin />}
          />
        </Routes>
    );
  }

  if (window.location.pathname.startsWith(pagePath.base_ma_redirect)) {
    return (
        <Routes>
            <Route exact path={pagePath.ma_redirect_signup} element={<WhileBrowserRedirect continuePath={pagePath.home} />} />
        </Routes>
    )
}

  // Unauthorized user routes
  if (signedIn === false) {
    return (
        <Layout>
          <Routes>
            {/* Blank path is used in health check */}
            {/* <Route exact path={pagePath.healthcheck} element={<Home />} /> */}
            <Route exact path={pagePath.home} element={<Home />} />
            <Route exact path={pagePath.healthcheck} element={<Home />} />

            {/* <Route exact path={pagePath.login} element={<LoginV2 />} /> */}
            <Route exact path={pagePath.signup} element={<Signup/>} />
            {/* <Route exact path={pagePath.otp_signup} element={<Signup1 />} /> */}
            <Route exact path={pagePath.loginV2} element={<LoginV2 />} />

            <Route
              exact
              path={pagePath.signup_confirm}
              element={<SignupConfirm />}
            />
            <Route
              exact
              path={pagePath.reset_password_initiate}
              element={<ResetPasswordInitiate />}
            />
            <Route
              exact
              path={pagePath.reset_password}
              element={<ResetPassword />}
            />
            <Route
              exact
              path={pagePath.signup}
              element={<Signup />}
            />
            <Route
              exact
              path={pagePath.userDetails}
              element={<UserDetails />}
            />
            <Route
              exact
              path={pagePath.success_signup}
              element={<Success_Signup />}
            />
            <Route
              exact
              path={pagePath.privacypolicy}
              element={<PrivacyPolicy />}
            />
            {/* <Route
              exact
              path={pagePath.landing_page}
              element={<LandingPage />}
            /> */}
            <Route
              path="*"
              element={
                <Navigate to={pagePath.unauthenticated_default} replace />
              }
            />
          </Routes>
        </Layout>
    );
  }

  // Authorized user routes
  return (
      <Layout>
        <TimeoutWarning />

        <Routes>
          <Route exact path={pagePath.aboutus} element={<AboutUs />} />
          <Route exact path={pagePath.profile} element={<Profile />} />
          <Route exact path={pagePath.past} element={<Past />} />
          <Route exact path={pagePath.landing_page} element={<Dashboard />} />
          <Route exact path={pagePath.evolve} element={<Evolve />} />
          <Route exact path={pagePath.contactUs} element={<ContactUs />} />
          <Route
            exact
            path={pagePath.dashboard}
            element={<LandingPage />}
          />
          {/* <Route exact path={pagePath.apireport} element={<ApiReport />} />
          <Route exact path={pagePath.vedicastro} element={<VedicAstro />} /> */}
          <Route
            path="*"
            element={<Navigate to={pagePath.dashboard} replace />}
          />
        </Routes>
      </Layout>
  );
};

export default App;
