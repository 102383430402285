import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import dayjs from "dayjs";
import "./profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEdit,
  faSpinner,
  faUser,
  faMobileAlt,
  faEnvelope,
  faMapMarkerAlt,
  faBirthdayCake,
  faClock,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Link } from "react-router-dom";
import { handleSignout } from "../../util/functions/handleSignout";
import { library } from "@fortawesome/fontawesome-svg-core";
import AnimatedLink from "../Animation/AnimatedLink";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import ProgressPage from "../../components/progressPage/ProgressPage";

const Profile = () => {
  const image = "";
  const [userData, setUserData] = useState({});
  const email_address = userData.email_address;
  const name = userData.name;
  const [dob, setDOB] = useState("");
  const editableTobInitial = userData.time_of_birth || "";
  const editableLobInitial = userData.location || "";
  const [error, setError] = useState(null);
  // const [editableTob, setEditableTob] = useState(editableTobInitial);
  const [editableTob, setEditableTob] = useState("");
  const [isEditableEmail, setIsEditableEmail] = useState(false); // New state for email editing
  const [emailAddress, setNewEmail] = useState("");
  library.add(
    faUser,
    faMobileAlt,
    faEnvelope,
    faMapMarkerAlt,
    faBirthdayCake,
    faClock
  );
  const [editableLob, setEditableLob] = useState(editableLobInitial);
  const [location, setLocation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isEditableTob, setIsEditableTob] = useState(false);
  const [isEditableLob, setIsEditableLob] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [latitude, setLatitude] = useState(userData.latitude);
  const [longitude, setLongitude] = useState(userData.longitude);
  const [mobile_number, setMobile_number] = useState(userData.mobile_number);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [isSaving, setIsSaving] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [showCloseAccountWarning, setShowCloseAccountWarning] = useState(false);
  const [isClosingAccount, setIsClosingAccount] = useState(false);
  const birthplaceInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const [triggerError, setTriggerError] = useState(false);

  const handleEditClick = () => {
    setIsEditableTob(!isEditableTob);
    setSaveEnabled(false);
  };

  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    const timeoutId = setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const handleEditLobClick = () => {
    setIsEditableLob(!isEditableLob);
    setSaveEnabled(false);
    if (!isEditableLob) {
      setLocation("");
      setIsEditableLob(true);
      setTimeout(() => {
        birthplaceInputRef.current.focus();
      }, 0);
    }
  };
  const handleEditEmailClick = () => {
    // Function to handle email editing
    setIsEditableEmail(!isEditableEmail);
    setSaveEnabled(false);
    if (!isEditableEmail) {
      setNewEmail(email_address); // Set initial email value when editing starts
      setTimeout(() => {
        emailInputRef.current.focus();
      }, 0);
    }
  };

  const timeChange = (newTime) => {
    const formattedTime = dayjs(newTime).format("hh:mm A");
    setEditableTob(formattedTime);
    setSaveEnabled(true);
  };

  const handleLocationChange = async (e) => {
    const inputValue = e.target.value;
    setLocation(inputValue);

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          inputValue
        )}`
      );

      if (response.data.length > 0) {
        const suggestedLocations = response.data.map((location) => ({
          display_name: location.display_name,
          lat: location.lat,
          lon: location.lon,
        }));
        setSuggestions(suggestedLocations);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (selectedLocation) => {
    setLocation(selectedLocation.display_name);
    setLatitude(selectedLocation.lat);
    setLongitude(selectedLocation.lon);
    setSuggestions([]);
    setSaveEnabled(true);
  };

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
    setSaveEnabled(true);
  };
  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes, seconds] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleSave = async () => {
    setIsSaving(true);
    let payload = {};

    if (emailAddress !== userData.email_address) {
      payload.email_address = emailAddress;
    }

    if (
      location !== userData.location ||
      latitude !== userData.latitude ||
      longitude !== userData.longitude
    ) {
      payload.location = location;
      payload.latitude = latitude;
      payload.longitude = longitude;
    }

    if (isEditableTob) {
      const Time = selectedTime.format("hh:mm A");
      const [time, modifier] = Time.split(" ");
      const modifiedTime = time + ":00" + " " + modifier;
      const Time24 = convertTo24Hour(modifiedTime);
      const dateTime = dob + " " + Time24;
      payload.birth_dt = dateTime;
    }

    try {
      const update_userDetails = await standardBizApiClient.update_userDetails(
        payload
      );

      if (update_userDetails.status_code === 200) {
        setSuccessMessage("Details Updated Successfully");
        setTimeout(() => {
          setSuccessMessage(null);
          window.location.reload();
        }, 2000);
      }
      setSaveEnabled(false);
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setIsSaving(false);
    }
  };
  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    setSaveEnabled(true);
  };

  useEffect(() => {
    const convertTo12Hour = (time24h) => {
      const [hours, minutes, seconds] = time24h.split(":");

      let hour = parseInt(hours, 10);
      const ampm = hour >= 12 ? "PM" : "AM";

      hour = hour % 12;
      hour = hour ? hour : 12; // Handle midnight (0) as 12 AM

      return `${hour}:${minutes}:${seconds} ${ampm}`;
    };
    const fetchData = async () => {
      try {
        const getUserData = await standardBizApiClient.get_userDetails();
        const [date, time] = getUserData.birth_dt.split(" ");
        setDOB(date);
        setUserData(getUserData);
        setEditableTob(convertTo12Hour(time) || "");
        setEditableLob(getUserData.location || "");
        setLocation(getUserData.location || "");
        setLatitude(getUserData.latitude);
        setLongitude(getUserData.longitude);
        setNewEmail(getUserData.email_address || "");
        setMobile_number(getUserData.mobile_number);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    fetchData();
  }, []);
  const handleCloseAccount = () => {
    setShowCloseAccountWarning(true);
  };
  const confirmCloseAccount = async () => {
    setIsClosingAccount(true);
    try {
      const response = await standardBizApiClient.account_deactivate();
      if (response.status === 200) {
        setShowCloseAccountWarning(false);
        setSuccessMessage(
          "Your account has been successfully deactivated. You will be logged out."
        );
      } else {
        setError("Failed to deactivate account. Please try again later.");
      }
    } catch (error) {
      console.error("Error during account deactivation:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setIsClosingAccount(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] flex flex-col items-center text-gray-800">
      {/* Header Section - Profile Photo Centered */}
      <div className="flex items-center">
        <Link to="/dashboard">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="absolute top-[22px] left-6 text-white text-4xl w-auto mr-4"
          />
        </Link>
      </div>
      <div className="w-full p-4 flex justify-center items-center rounded-b-lg">
        <div className="text-white text-center">
          <img
            src="/chakra.svg"
            alt="avatar"
            className="w-24 h-24 rounded-full border-4 border-white shadow-lg mx-auto"
          />
          <div className="mt-2 text-xl font-rale font-semibold">{name}</div>
          <div className="text-sm font-rale">{email_address}</div>
        </div>
      </div>

      {/* Account Info */}
      <div className="container mx-auto p-6">
        <div className="text-2xl font-semibold text-center mb-6 text-white font-rale">
          Account Details
        </div>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Name */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="user"
              size="lg"
            />
            <div>
              <div className=" font-rale ">
                <span className="font-semibold">Name:</span>
                <span className="text-white">..</span> {name}
              </div>
            </div>
          </li>

          {/* Mobile */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="mobile-alt"
              size="lg"
            />
            <div>
              <div className="">
                <span className="font-semibold font-rale">Mobile:</span>
                <span className="text-white">.</span> {mobile_number}
              </div>
              <div></div>
            </div>
          </li>

          {/* Email */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="envelope"
              size="lg"
            />
            <div>
              <div className="font-rale font-semibold">Email:</div>
              {isEditableEmail ? (
                <div className="emailInput--Container">
                  <input
                    type="email"
                    placeholder="Email"
                    value={emailAddress}
                    onChange={handleEmailChange}
                    className="border-2 border-purple-200 rounded-lg p-2 w-full focus:outline-none"
                    ref={emailInputRef}
                  />
                  <span className="edit-icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={handleEditEmailClick}
                      className="ml-2 cursor-pointer text-gray-600"
                    />
                  </span>
                </div>
              ) : (
                <div className="font-rale">
                  {email_address}
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={handleEditEmailClick}
                    className="ml-2 cursor-pointer text-gray-600"
                  />
                </div>
              )}
            </div>
          </li>

          {/* Time of Birth */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="clock"
              size="lg"
            />
            <div>
              <div className="font-rale font-semibold">Time Of Birth:</div>
              <div>
                {isEditableTob ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={selectedTime}
                      onChange={handleTimeChange}
                      renderInput={(params) => (
                        <input {...params} className="p-2 border rounded-md" />
                      )}
                    />
                    <span className="edit-icon2">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => setIsEditableTob(false)}
                        className="ml-2 cursor-pointer text-gray-600"
                      />
                    </span>
                  </LocalizationProvider>
                ) : (
                  <div>
                    {editableTob}
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => setIsEditableTob(true)}
                      className="ml-2 cursor-pointer text-gray-600"
                    />
                  </div>
                )}
              </div>
            </div>
          </li>

          {/* Date of Birth */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="birthday-cake"
              size="lg"
            />
            <div>
              <div className="">
                <span className="font-semibold font-rale">Date Of Birth:</span>
                <span className="text-white">..</span> {dob}
              </div>
            </div>
          </li>

          {/* Location of Birth */}
          <li className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="text-purple-500"
              icon="map-marker-alt"
              size="lg"
            />
            <div>
              <div className="font-semibold font-rale">Location Of Birth:</div>
              {isEditableLob ? (
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Birthplace"
                    value={location}
                    onChange={handleLocationChange}
                    className="border-2 border-purple-200 font-rale rounded-lg p-2 w-full "
                  />
                  {suggestions.length > 0 && (
                    <div className="absolute z-10 bg-white border-2 rounded-lg w-full mt-2">
                      {suggestions.map((loc, idx) => (
                        <div
                          key={idx}
                          className="p-2 font-rale cursor-pointer hover:bg-gray-200"
                          onClick={() => handleSelectSuggestion(loc)}
                        >
                          {loc.display_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {editableLob}
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={handleEditLobClick}
                    className="ml-2 cursor-pointer text-gray-600"
                  />
                </div>
              )}
            </div>
          </li>
        </ul>

        {/* Buttons */}
        <div className="mt-8 mb-10 max-sm:mb-14 flex max-sm:flex-col max-sm:space-y-4 space-y-0 space-x-4 max-sm:space-x-0 items-center max-sm:w-full">
          <button
            onClick={handleSave}
            className={`w-full max-sm:w-full px-6 py-2 rounded-full bg-[#9BDC1E] text-white font-semibold shadow-lg ${
              saveEnabled
                ? "hover:bg-[#86b136]"
                : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!saveEnabled}
          >
            {isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
          </button>

          <button
            onClick={handleSignout}
            className="w-full max-sm:w-full px-6 py-2 rounded-full bg-[#F45A5A] text-white font-semibold shadow-lg hover:bg-[#dd5d5d]"
          >
            {isSigningOut ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Sign Out"
            )}
          </button>

          <button
            onClick={handleCloseAccount}
            className="w-full max-sm:w-full px-6 py-2 rounded-full bg-red-500 text-white font-semibold shadow-lg hover:bg-red-600"
            disabled={isSaving || isSigningOut || isClosingAccount}
          >
            {isClosingAccount ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Close Account"
            )}
          </button>
        </div>

        {/* Close Account Warning */}
        {showCloseAccountWarning && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <p className="text-lg font-rale font-semibold text-red-500">
                Are you sure you want to close your account?
              </p>
              <p className="mt-4 font-rale font-semibold">This action is irreversible.</p>
              <div className="mt-6 flex justify-around">
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded-full shadow-lg hover:bg-red-600"
                  onClick={() => {
                    confirmCloseAccount();
                    setIsSigningOut(true);
                    handleSignout();
                  }}
                >
                  Confirm
                </button>
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded-full shadow-lg hover:bg-gray-600"
                  onClick={() => setShowCloseAccountWarning(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default () => (
  <ErrorBoundary fallback={<ProgressPage />}>
    <Profile />
  </ErrorBoundary>
);
