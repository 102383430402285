import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/customMui/Button";
import "./signupDisallowed.css";

const SignupDisallowed = ({ 
    onCancel, 
    continuePath
}) => {
    const { platform, os } = useSelector((state) => state.device_context);
    // determine which message to show
    const message = useMemo(() => {
        let retval = (
            <p>Please contact our support team to subscribe to sign up</p> // default, not meant to be used
        );
        if (platform === "mobile_app") {
            retval = (
                <div className="signup-disallowed-multiline-message">
                    <p>Please click the button below to complete the signup process on our website.</p>
                    <p>Then come back to the app and login to choose which certifications you wanna practice.</p>                    
                </div>
            )
        }        
        return retval;
    }, [platform, os]);
    // determine which button to show
    const actionButton = useMemo(() => {
        let retval = null;
        if (platform === "mobile_app") {
            retval = (
                <Button                
                    variant="contained"
                    customProps={{
                        onClick: () => window.location.href = continuePath
                    }}
                >
                    Continue in browser
                </Button> 
            )
        }        
        return retval;
    }, [platform, os]);
    return (
        <div className="signup-disallowed">
            {message}
            {actionButton}
            <Button                
                variant="contained"
                customProps={{
                    onClick: onCancel
                }}
            >
                Back
            </Button>  
            <p className="signup-disallowed-footer">
                Any questions? Please reach out to us at <br/> <a href="mailto:success@destini.ai">success@destini.ai</a>
            </p>
        </div>
    )
}
export default SignupDisallowed;