import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, primaryRole } from "../../redux/slices/userSlice";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import { handleSignout } from "../../util/functions/handleSignout";

const SupportLogin = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const signedIn = useSelector((state) => state.user.signedIn);

    useEffect(() => {
        handleSupportLogin();
    }, [])

    const handleSupportLogin = async () => {
        if (signedIn) {
            await handleSignout();
        }

        const query = new URLSearchParams(search, [search]);
        const userId = query.get("user_id");
        const token = query.get("token");
        const session = query.get("session");
        if (!userId || !token || !session) return;

        const supportLoginRes = await standardBizApiClient.account_support_login(userId, token, session);
        if (supportLoginRes?.is_error) {
            return;
        }

        const { certs, primary_role, exam_meta } = supportLoginRes;
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certs;
        const { exam_type } = exam_meta;
        const newIsExamInProgress = Boolean(exam_type);

        dispatch(login());
        dispatch(primaryRole(primary_role));
        window.location.href = "/";
    }

    return (
        <div>

        </div>
    )
}

export default SupportLogin;