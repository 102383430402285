import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faSearch,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faThumbsUp,
  faThumbsDown,
  faClock,
  faCaretLeft,
  faCaretRight,
  faHistory,
  faCalendarDay,
  faCalendarAlt,
  faAngleRight,
  faAngleLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import destini from "../../components/images/mainlogo.png";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import { Link, useNavigate } from "react-router-dom";
import addNotification from "react-push-notification";
import dayjs from "dayjs";
import { useSwipeable } from "react-swipeable";
import { ClipLoader } from "react-spinners";
import eyeImage from "./swastika.gif";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import { motion } from "framer-motion";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowAltCircleLeft, faArrowAltCircleRight, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import ProgressPage from "../../components/progressPage/ProgressPage";

const LandingPage = () => {
  const [todayMessage, setTodayMessage] = useState([]);
  const [todayPredictionID, setTodayPredictionID] = useState("");
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [tomorrowMessage, setTomorrowMessage] = useState([]);
  const [tomorrowPredictionID, setTomorrowPredictionID] = useState("");
  const [tomorrowStartDate, setTomorrowStartDate] = useState("");
  const [tomorrowEndDate, setTomorrowEndDate] = useState("");
  const [destiniData, setDestiniData] = useState(null);
  const [todayFeedback, setTodayFeedback] = useState(null);
  const [todayGoodClicked, setTodayGoodClicked] = useState(false);
  const [todayBadClicked, setTodayBadClicked] = useState(false);
  const [tomorrowFeedback, setTomorrowFeedback] = useState(null);
  const [tomorrowGoodClicked, setTomorrowGoodClicked] = useState(false);
  const [tomorrowBadClicked, setTomorrowBadClicked] = useState(false);
  const [predictionMessage, setPredictionMessage] = useState(todayMessage);
  const [selectedDate, setSelectedDate] = useState("Now");
  const [yesterdayMessage, setYesterdayMessage] = useState([]);
  const [yesterdayPredictionID, setYesterdayPredictionID] = useState("");
  const [yesterdayStartDate, setYesterdayStartDate] = useState("");
  const [yesterdayEndDate, setYesterdayEndDate] = useState("");
  const [predictionList, setPredictionList] = useState([]);
  const [selectedDay, setSelectedDay] = useState("Now");
  const currentDate = new Date();
  const options = { month: "long", day: "numeric" };
  const Today = currentDate.toLocaleString("en-US", options);
  const [todayPredictionFeedback, setTodayPredictionFeedback] = useState(null);
  const [tomorrowPredictionFeedback, setTomorrowPredictionFeedback] =
    useState(null);
  const [yesterdayFeedback, setYesterdayFeedback] = useState(null);
  const [yesterdayGoodClicked, setYesterdayGoodClicked] = useState(false);
  const [yesterdayBadClicked, setYesterdayBadClicked] = useState(false);
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const Yesterday = yesterdayDate.toLocaleString("en-US", options);
  const [todayStartDate, setTodayStartDate] = useState("");
  const [todayEndDate, setTodayEndDate] = useState("");
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  const Tomorrow = tomorrowDate.toLocaleString("en-US", options);
  const navigate = useNavigate();
  const [isLoadingTomorrow, setIsLoadingTomorrow] = useState(false);
  const [showNewPredictionAlert, setShowNewPredictionAlert] = useState(false);
  const [todayPredictions, setTodayPredictions] = useState([]);
  const [yesterdayPredictions, setYesterdayPredictions] = useState([]);
  const [todayPredictionIndex, setTodayPredictionIndex] = useState(0);
  const [yesterdayPredictionIndex, setYesterdayPredictionIndex] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isLoadingPast, setIsLoadingPast] = useState(false);
  const [isLoadingNow, setIsLoadingNow] = useState(false);
  const [triggerError, setTriggerError] = useState(false);

  const getFormattedDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const cleanPredictionDesc = (desc) => {
    // Remove backslashes and trim the string, then remove starting and ending quotes
    const cleanedDesc = desc.replace(/\\/g, "").trim();
    return cleanedDesc.replace(/^"(.*)"$/, "$1");
  };

  const predictionSplit = (prediction) => {
    const cleanedPrediction = cleanPredictionDesc(prediction);
    const sentences = cleanedPrediction
      .split(".")
      .filter((sentence) => sentence.trim() !== "");
    const sentencesPerParagraph = Math.ceil(sentences.length / 5);
    const paragraphs = [];

    for (let i = 0; i < sentences.length; i += sentencesPerParagraph) {
      paragraphs.push(sentences.slice(i, i + sentencesPerParagraph).join(". "));
    }

    return paragraphs;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingTomorrow(true);

      try {
        const currentDate = new Date();
        const predictionData = await standardBizApiClient.get_destini_data();
        const predictions = predictionData.result;
        let todayPrediction = null;
        let yesterdayPrediction = null;
        let tomorrowPrediction = null;

        const filterPredictionsByDate = (date, predictions) => {
          return predictions.filter((item) => {
            const startDate = dayjs(item.prediction.start_date);
            const endDate = dayjs(item.prediction.end_date);
            const selected = dayjs(date);
            return (
              selected.isSame(startDate, "day") ||
              selected.isSame(endDate, "day") ||
              (selected.isAfter(startDate) && selected.isBefore(endDate))
            );
          });
        };

        let todayPredictions = filterPredictionsByDate(
          currentDate,
          predictions
        );
        todayPredictions.sort(
          (a, b) =>
            new Date(a.prediction.end_date) - new Date(b.prediction.end_date)
        );
        setTodayPredictions(todayPredictions);

        if (todayPredictions.length === 0) {
          console.error(`No predictions found on ${currentDate}`);
        } else {
          todayPrediction = todayPredictions[0];
          setTodayPredictionIndex(0);
          const lastPrediction = todayPredictions[todayPredictions.length - 1];
          const storedEndDate = localStorage.getItem("endDate");
          if (storedEndDate !== lastPrediction.prediction.end_date) {
            setShowNewPredictionAlert(true);
          }
          localStorage.setItem(
            "startDate",
            lastPrediction.prediction.start_date
          );
          localStorage.setItem("endDate", lastPrediction.prediction.end_date);
        }

        const yesterdayDate = new Date(currentDate);
        yesterdayDate.setDate(currentDate.getDate() - 1);
        const yesterdayPredictions = filterPredictionsByDate(
          yesterdayDate,
          predictions
        );
        yesterdayPredictions.sort(
          (a, b) =>
            new Date(a.prediction.end_date) - new Date(b.prediction.end_date)
        );
        setYesterdayPredictions(yesterdayPredictions);

        if (yesterdayPredictions.length > 0) {
          yesterdayPrediction = yesterdayPredictions[0];
          setYesterdayPredictionIndex(0);
        }

        const tomorrowDate = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate() + 1);
        const tomorrowPredictions = filterPredictionsByDate(
          tomorrowDate,
          predictions
        );
        tomorrowPredictions.sort(
          (a, b) =>
            new Date(a.prediction.end_date) - new Date(b.prediction.end_date)
        );
        setTomorrowMessage(tomorrowPredictions);

        if (tomorrowPredictions.length > 0) {
          tomorrowPrediction = tomorrowPredictions[0];
        }

        if (todayPrediction) {
          setTodayPredictionID(todayPrediction.prediction.prediction_id);
          setTodayStartDate(todayPrediction.prediction.start_date);
          setTodayEndDate(todayPrediction.prediction.end_date);
          const cleanedDesc = cleanPredictionDesc(
            todayPrediction.prediction.prediction_desc.trim() ||
              todayPrediction.prediction.master_interpretation
          );
          const paragraphs = predictionSplit(cleanedDesc);
          setPredictionList(paragraphs);
          setTodayMessage(paragraphs);
          setPredictionMessage(cleanedDesc);
          if (todayPrediction.prediction.user_feedback) {
            setTodayFeedback(todayPrediction.prediction.user_feedback);
            if (todayPrediction.prediction.user_feedback === "1") {
              setTodayGoodClicked(true);
            } else {
              setTodayBadClicked(true);
            }
          }
        }
        if (yesterdayPrediction) {
          const cleanedDesc = cleanPredictionDesc(
            yesterdayPrediction.prediction.prediction_desc.trim() ||
              yesterdayPrediction.prediction.master_interpretation
          );
          const paragraphs = predictionSplit(cleanedDesc);
          setYesterdayMessage(paragraphs);
          setYesterdayPredictionID(
            yesterdayPrediction.prediction.prediction_id
          );
          setYesterdayStartDate(yesterdayPrediction.prediction.start_date);
          setYesterdayEndDate(yesterdayPrediction.prediction.end_date);
          if (yesterdayPrediction.prediction.user_feedback) {
            setYesterdayFeedback(yesterdayPrediction.prediction.user_feedback);
            if (yesterdayPrediction.prediction.user_feedback === "1") {
              setYesterdayGoodClicked(true);
            } else {
              setYesterdayBadClicked(true);
            }
          }
        }
        if (tomorrowPrediction) {
          const cleanedDesc = cleanPredictionDesc(
            tomorrowPrediction.prediction.prediction_desc.trim() ||
              tomorrowPrediction.prediction.master_interpretation
          );
          const paragraphs = predictionSplit(cleanedDesc);
          setTomorrowMessage(paragraphs);
          setTomorrowPredictionID(tomorrowPrediction.prediction.prediction_id);
          setTomorrowStartDate(tomorrowPrediction.prediction.start_date);
          setTomorrowEndDate(tomorrowPrediction.prediction.end_date);
          if (tomorrowPrediction.prediction.user_feedback) {
            setTomorrowFeedback(tomorrowPrediction.prediction.user_feedback);
            if (tomorrowPrediction.prediction.user_feedback === "1") {
              setTomorrowGoodClicked(true);
            } else {
              setTomorrowBadClicked(true);
            }
          }
        } else {
          setTomorrowMessage([]);
        }

        setIsLoadingTomorrow(false);
      } catch (error) {
        console.log("Error fetching prediction data:", error);
      }
      setIsLoadingTomorrow(false);
    };
    fetchData();
  }, [standardBizApiClient]);

  const handleNextPrediction = () => {
    if (
      selectedDate === "Now" &&
      todayPredictionIndex < todayPredictions.length - 1
    ) {
      const newIndex = todayPredictionIndex + 1;
      const todayPrediction = todayPredictions[newIndex];

      setTodayPredictionIndex(newIndex);
      setTodayPredictionID(todayPrediction.prediction.prediction_id);
      setTodayStartDate(todayPrediction.prediction.start_date);
      setTodayEndDate(todayPrediction.prediction.end_date);

      const cleanedDesc = cleanPredictionDesc(
        todayPrediction.prediction.prediction_desc.trim() ||
          todayPrediction.prediction.master_interpretation
      );
      const paragraphs = predictionSplit(cleanedDesc);
      setPredictionList(paragraphs);
      setTodayMessage(paragraphs);
      setPredictionMessage(cleanedDesc);

      if (todayPrediction.prediction.user_feedback) {
        setTodayFeedback(todayPrediction.prediction.user_feedback);
        if (todayPrediction.prediction.user_feedback === "1") {
          setTodayGoodClicked(true);
          setTodayBadClicked(false);
        } else {
          setTodayGoodClicked(false);
          setTodayBadClicked(true);
        }
      } else {
        setTodayGoodClicked(false);
        setTodayBadClicked(false);
      }
    } else if (
      selectedDate === "Past" &&
      yesterdayPredictionIndex < yesterdayPredictions.length - 1
    ) {
      const newIndex = yesterdayPredictionIndex + 1;
      const yesterdayPrediction = yesterdayPredictions[newIndex];

      setYesterdayPredictionIndex(newIndex);
      setYesterdayPredictionID(yesterdayPrediction.prediction.prediction_id);
      setYesterdayStartDate(yesterdayPrediction.prediction.start_date);
      setYesterdayEndDate(yesterdayPrediction.prediction.end_date);

      const cleanedDesc = cleanPredictionDesc(
        yesterdayPrediction.prediction.prediction_desc.trim() ||
          yesterdayPrediction.prediction.master_interpretation
      );
      const paragraphs = predictionSplit(cleanedDesc);
      setPredictionList(paragraphs);
      setYesterdayMessage(paragraphs);
      setPredictionMessage(cleanedDesc);

      if (yesterdayPrediction.prediction.user_feedback) {
        setYesterdayFeedback(yesterdayPrediction.prediction.user_feedback);
        if (yesterdayPrediction.prediction.user_feedback === "1") {
          setYesterdayGoodClicked(true);
          setYesterdayBadClicked(false);
        } else {
          setYesterdayGoodClicked(false);
          setYesterdayBadClicked(true);
        }
      } else {
        setYesterdayGoodClicked(false);
        setYesterdayBadClicked(false);
      }
    }
  };

  const handlePrevPrediction = () => {
    if (selectedDate === "Now" && todayPredictionIndex > 0) {
      const newIndex = todayPredictionIndex - 1;
      const todayPrediction = todayPredictions[newIndex];

      setTodayPredictionIndex(newIndex);
      setTodayPredictionID(todayPrediction.prediction.prediction_id);
      setTodayStartDate(todayPrediction.prediction.start_date);
      setTodayEndDate(todayPrediction.prediction.end_date);

      const cleanedDesc = cleanPredictionDesc(
        todayPrediction.prediction.prediction_desc.trim() ||
          todayPrediction.prediction.master_interpretation
      );
      const paragraphs = predictionSplit(cleanedDesc);
      setPredictionList(paragraphs);
      setTodayMessage(paragraphs);
      setPredictionMessage(cleanedDesc);

      if (todayPrediction.prediction.user_feedback) {
        setTodayFeedback(todayPrediction.prediction.user_feedback);
        if (todayPrediction.prediction.user_feedback === "1") {
          setTodayGoodClicked(true);
          setTodayBadClicked(false);
        } else {
          setTodayGoodClicked(false);
          setTodayBadClicked(true);
        }
      } else {
        setTodayGoodClicked(false);
        setTodayBadClicked(false);
      }
    } else if (selectedDate === "Past" && yesterdayPredictionIndex > 0) {
      const newIndex = yesterdayPredictionIndex - 1;
      const yesterdayPrediction = yesterdayPredictions[newIndex];

      setYesterdayPredictionIndex(newIndex);
      setYesterdayPredictionID(yesterdayPrediction.prediction.prediction_id);
      setYesterdayStartDate(yesterdayPrediction.prediction.start_date);
      setYesterdayEndDate(yesterdayPrediction.prediction.end_date);

      const cleanedDesc = cleanPredictionDesc(
        yesterdayPrediction.prediction.prediction_desc.trim() ||
          yesterdayPrediction.prediction.master_interpretation
      );
      const paragraphs = predictionSplit(cleanedDesc);
      setPredictionList(paragraphs);
      setYesterdayMessage(paragraphs);
      setPredictionMessage(cleanedDesc);

      if (yesterdayPrediction.prediction.user_feedback) {
        setYesterdayFeedback(yesterdayPrediction.prediction.user_feedback);
        if (yesterdayPrediction.prediction.user_feedback === "1") {
          setYesterdayGoodClicked(true);
          setYesterdayBadClicked(false);
        } else {
          setYesterdayGoodClicked(false);
          setYesterdayBadClicked(true);
        }
      } else {
        setYesterdayGoodClicked(false);
        setYesterdayBadClicked(false);
      }
    }
  };

  // const displayNotification = () => {
  //   addNotification({
  //     title: "Destini.ai",
  //     message: "A new prediction is available for Today. Check it out now!",
  //     theme: "light",
  //     closeButton: "X",
  //     duration: 8000,
  //     onClick: () => {
  //       window.location.href = "/dashboard";
  //     },
  //     native: true,
  //     customClass: "custom-notification", // Apply the custom class here
  //   });

  //   const audio = new Audio("notification.mp3");
  //   audio.play().catch((error) => {
  //     console.error("Failed to play audio:", error);
  //   });
  // };

  // function alertWithSound() {
  //   const audio = new Audio("notification.mp3");

  //   audio.play().catch((error) => {
  //     console.error("Failed to play audio:", error);
  //   });

  //   audio.onended = () => {
  //     addNotification({
  //       title: "Destini.ai",
  //       message: "A new prediction is available for Today. Check it out now!",
  //       theme: "light",
  //       closeButton: "X",
  //       duration: 8000,
  //       onClick: () => {
  //         window.location.href = "/dashboard";
  //       },
  //       customClass: "custom-notification", // Apply the custom class here
  //     });
  //   };
  // }

  // useEffect(() => {
  //   if (showNewPredictionAlert) {
  //     if ("Notification" in window) {
  //       if (Notification.permission === "granted") {
  //         displayNotification();
  //       } else if (Notification.permission !== "denied") {
  //         Notification.requestPermission().then((permission) => {
  //           if (permission === "granted") {
  //             displayNotification();
  //           }
  //         });
  //       } else {
  //         alertWithSound();
  //       }
  //     } else {
  //       alertWithSound();
  //     }
  //     setShowNewPredictionAlert(false);
  //   }
  // }, [showNewPredictionAlert]);

  const handleDateBoxClick = async (date) => {
    setSelectedDate(date);
    setSelectedDay(date);
    const selectedDateBox = document.querySelector(".date-box.selected");
    setIsLoadingTomorrow(false);
    if (selectedDateBox) {
      selectedDateBox.style.transition = "none";
      selectedDateBox.style.animation = "none";
    }
    if (date === "Past") {
      setPredictionList(yesterdayMessage);
    } else if (date === "Now") {
      setPredictionList(todayMessage);
    } else if (date === "Next") {
      if (tomorrowMessage.length === 0) {
        setIsLoadingTomorrow(true);
        const tomorrowDate = new Date(currentDate);
        const year = tomorrowDate.getFullYear();
        const month = String(tomorrowDate.getMonth() + 1).padStart(2, "0");
        const day = String(tomorrowDate.getDate()).padStart(2, "0");
        const nextday = String(Number(day) + 1).padStart(2, "0");
        const formattedTomorrowDate = `${year}-${month}-${nextday}`;
        try {
          const res = await standardBizApiClient.get_tomorrow_destini(
            formattedTomorrowDate
          );
          console.log(res);
          if (res.status !== 200) {
            setIsLoadingTomorrow(false);
          }
          if (res && res.result) {
            try {
              const sentences =
                res.result.prediction.prediction_desc.split(".");
              const sentencesPerParagraph = Math.ceil(sentences.length / 5);
              const paragraphs = [];
              if (sentences) {
                for (
                  let i = 0;
                  i < sentences.length;
                  i += sentencesPerParagraph
                ) {
                  paragraphs.push(
                    sentences.slice(i, i + sentencesPerParagraph).join(".")
                  );
                }
              }
              setTomorrowMessage(paragraphs);
              setTomorrowPredictionID(res.result.prediction.prediction_id);
              setTomorrowStartDate(res.result.prediction.start_date);
              setTomorrowEndDate(res.result.prediction.end_date);
              setPredictionMessage(res.result.prediction.prediction_desc);
              setIsLoadingTomorrow(false);
            } catch (error) {
              console.error("tomorrow prediction", error);
              setIsLoadingTomorrow(false);
            }
          }
        } catch (error) {
          console.error("Error fetching tomorrow's prediction:", error);
          setIsLoadingTomorrow(false);
        }
      }
      setPredictionList(tomorrowMessage);
    }
  };
  const handleGoodResponse = async (day) => {
    let predictionID;
    if (day === "Now") {
      predictionID = todayPredictionID;
    } else if (day === "Next") {
      predictionID = tomorrowPredictionID;
    } else if (day === "Past") {
      predictionID = yesterdayPredictionID;
    }
    const updateRes = await standardBizApiClient.update_user_feedback(
      predictionID,
      "1"
    );
    if (updateRes.status_code === 200) {
      if (day === "Now") {
        setTodayGoodClicked(true);
        setTodayBadClicked(false);
        setTodayFeedback("1");
      } else if (day === "Next") {
        setTomorrowGoodClicked(true);
        setTomorrowBadClicked(false);
        setTomorrowFeedback("1");
      } else if (day === "Past") {
        setYesterdayGoodClicked(true);
        setYesterdayBadClicked(false);
        setYesterdayFeedback("1");
      }
    }
  };
  const handleBadResponse = async (day) => {
    let predictionID;
    if (day === "Now") {
      predictionID = todayPredictionID;
    } else if (day === "Next") {
      predictionID = tomorrowPredictionID;
    } else if (day === "Past") {
      predictionID = yesterdayPredictionID;
    }
    const updateRes = await standardBizApiClient.update_user_feedback(
      predictionID,
      "0"
    );
    if (updateRes.status_code === 200) {
      if (day === "Now") {
        setTodayGoodClicked(false);
        setTodayBadClicked(true);
        setTodayFeedback("0");
      } else if (day === "Next") {
        setTomorrowGoodClicked(false);
        setTomorrowBadClicked(true);
        setTomorrowFeedback("0");
      } else if (day === "Past") {
        setYesterdayGoodClicked(false);
        setYesterdayBadClicked(true);
        setYesterdayFeedback("0");
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPrediction(),
    onSwipedRight: () => handlePrevPrediction(),
    onSwipeStart: () => setIsSwiping(true),
    onSwipeEnd: () => setIsSwiping(false),

    delta: 10,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  });

  const evolvepage = () => {
    navigate(pagePath.evolve, { state: { todayPredictionID } });
  };
  const buttonTitle = "Evolve";

  return (
    <motion.div
      className="min-h-screen bg-gray-50 flex flex-col items-center p-4"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.2, ease: "easeInOut" }}
    >
      {/* Destini Logo in the top-left corner */}
      <div className="fixed top-4 left-4 max-sm:top-3 max-sm:left-3 z-50">
        <motion.img
          src="/dst-logo.svg" // replace with your actual logo path
          alt="Destini Logo"
          className="w-24 h-24 max-sm:w-10 max-sm:h-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        />
      </div>

      {/* Valid From - Now inside a box with shadow */}
      <motion.div
        className="bg-white max-sm:mt-10  shadow-md rounded-lg p-2 mb-4 max-w-[32rem] w-full flex items-center justify-center"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.3, type: "spring", stiffness: 70 }}
      >
        <motion.img
          src="/circle3.svg"
          alt="Valid"
          className="w-7 h-7 mr-2"
          initial={{ rotate: 360, scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ duration: 0.6, bounce: 0.3 }}
        />
        <motion.p
          className="text-gray-500 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          Valid from
          {selectedDay === "Past" && (
            <>
              <span className="font-medium max-sm:text-xs text-[#9BDC1E]">
                {" "}
                {getFormattedDate(yesterdayStartDate)}
              </span>{" "}
              <span className="font-medium">to</span>
              <span className="font-medium text-[#FF9F3A]">
                {" "}
                {getFormattedDate(yesterdayEndDate)}
              </span>
            </>
          )}
          {selectedDay === "Now" && (
            <>
              <span className="font-medium text-[#9BDC1E]">
                {" "}
                {getFormattedDate(todayStartDate)}
              </span>{" "}
              <span className="font-medium">to</span>
              <span className="font-medium text-[#FF9F3A]">
                {" "}
                {getFormattedDate(todayEndDate)}
              </span>
            </>
          )}
          {selectedDay === "Next" && (
            <>
              <span className="font-medium text-[#9BDC1E]">
                {" "}
                {getFormattedDate(tomorrowStartDate)}
              </span>{" "}
              <span className="font-medium">to</span>
              <span className="font-medium text-[#FF9F3A]">
                {" "}
                {getFormattedDate(tomorrowEndDate)}
              </span>
            </>
          )}
        </motion.p>
      </motion.div>

      {/* Prediction Box */}
      <motion.div
        className="bg-white shadow-md rounded-lg pr-6 pt-6 pb-6 pl-1 max-w-[750px] w-full"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, type: "spring", damping: 20 }}
      >
        {/* Prediction Content with a taller height */}
        <motion.div
          {...handlers}
          className={`text-gray-700 text-base mb-4 overflow-y-auto max-h-[170px] max-sm:max-h-[12rem] scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-blue-500 scrollbar-track-gray-100`}
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, type: "spring", bounce: 0.4 }}
        >
          <ul className="list-none ml-4 space-y-2">
            {predictionList.map(
              (paragraph, index) =>
                paragraph.trim() !== "" && (
                  <motion.li
                    key={index}
                    className="flex items-start"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                  >
                    {/* Small chakra image as bullet point */}
                    <motion.img
                      src="/chakra.svg"
                      alt="bullet"
                      className="w-5 h-5 mr-2"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.3 }}
                    />
                    <span>{paragraph}.</span>
                  </motion.li>
                )
            )}
          </ul>
        </motion.div>

        {/* Loading Eye Animation */}
        {isLoadingTomorrow && (
          <motion.div
            className="flex flex-col items-center mb-4"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0.6 }}
          >
            {/* Custom Loading Spinner */}
            <motion.div
              className="w-16 h-16 border-t-4 border-b-4 border-[#52C3E6] rounded-full"
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                duration: 1,
                ease: "linear",
              }}
            />
            <p className="text-sm font-bold text-gray-500 font-rale mt-2">
              Loading your Destini...
            </p>
          </motion.div>
        )}
      </motion.div>

      <motion.div
        className="mt-6 flex space-x-10 max-sm:space-x-2"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.7, type: "spring", damping: 15 }}
      >
        {/* Past Date */}
        <motion.div
          className={`flex flex-col items-center p-4 !pl-6 !pr-6 max-sm:!pl-4 max-sm:!pr-4 bg-orange-100 rounded-lg cursor-pointer ${
            selectedDate === "Past" ? "bg-orange-200" : ""
          } ${selectedDate !== "Past" ? "blur-[1px] opacity-95" : ""}`} // Apply blur and opacity when not selected
          whileHover={{ scale: selectedDate === "Past" ? 1.2 : 1.1 }}
          onClick={() => handleDateBoxClick("Past")}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="text-orange-500 text-xl max-sm:text-lg mb-2"
          />
          <h3 className="text-lg max-sm:text-sm font-semibold">Past</h3>
          <p className="text-sm max-sm:text-xs">{Yesterday}</p>
        </motion.div>

        {/* Current Date */}
        <motion.div
          className={`flex flex-col items-center p-4 !pl-14 !pr-14 max-sm:!pl-7 max-sm:!pr-7 bg-blue-100 rounded-lg cursor-pointer ${
            selectedDate === "Now" ? "bg-blue-200" : ""
          } ${selectedDate !== "Now" ? "blur-[1px] opacity-95" : ""}`} // Apply blur and opacity when not selected
          whileHover={{ scale: selectedDate === "Now" ? 1.2 : 1.1 }}
          onClick={() => handleDateBoxClick("Now")}
        >
          <FontAwesomeIcon
            icon={faCalendarDay}
            className="text-blue-500 text-xl max-sm:text-lg mb-2"
          />
          <h3 className="text-lg max-sm:text-sm font-semibold">Now</h3>
          <p className="text-sm max-sm:text-xs">{Today}</p>
        </motion.div>

        {/* Future Date */}
        <motion.div
          className={`flex flex-col items-center p-4 !pl-6 !pr-6 max-sm:!pl-4 max-sm:!pr-4 bg-purple-100 rounded-lg cursor-pointer ${
            selectedDate === "Next" ? "bg-purple-200" : ""
          } ${selectedDate !== "Next" ? "blur-[1px] opacity-95" : ""}`} // Apply blur and opacity when not selected
          whileHover={{ scale: selectedDate === "Next" ? 1.2 : 1.1 }}
          onClick={() => handleDateBoxClick("Next")}
          disabled={isLoadingTomorrow}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className="text-purple-500 text-xl max-sm:text-lg mb-2"
          />
          <h3 className="text-lg max-sm:text-sm font-semibold">Next</h3>
          <p className="text-sm max-sm:text-xs">{Tomorrow}</p>
        </motion.div>
      </motion.div>

      {/* Evolve Button */}
      <motion.div
        className="mt-4 text-center max-sm:mt-6"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8, type: "spring", stiffness: 100 }}
      >
        <motion.button
          className="px-6 py-2 bg-[#FF9F3A] max-sm:py-4 text-white rounded-full"
          onClick={evolvepage}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.2 }}
        >
          Feedback
        </motion.button>
      </motion.div>

      {/* Note */}
      <motion.div
        className="mt-2 font-rale text-sm text-gray-500 max-sm:fixed max-sm:bottom-[65px] max-sm:w-full max-sm:bg-gray-50 max-sm:p-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <p className="font-rale text-xs text-center text-gray-300">
          <span className="font-bold">DISCLAIMER:</span> We are constantly
          improving the system to make the accurate predictions, destini.ai may
          make mistakes in prediction.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default () => (
  <ErrorBoundary fallback={<ProgressPage />}>
    <LandingPage />
  </ErrorBoundary>
);
