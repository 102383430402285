import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    platform: null, // null, "mobile_app"
    os: null, // null, "ios", "android"
}
export const DeviceContextSlice = createSlice({
    name: "device_context",
    initialState: initialState,
    reducers: {
        setPlatform: (state, action) => {
            state.platform = action.payload;
        },
        setOs: (state, action) => {
            state.os = action.payload;
        },
        resetDeviceContext: (state, action) => {
            // looping keys because we can't set state = initialState
            // probably a better way of doing this. havent looked into it yet
            for (const key in initialState) {
                state[key] = initialState[key];
            }
            // return initialState;
        }
    }
})
export const { setPlatform, setOs, resetDeviceContext } = DeviceContextSlice.actions
export default DeviceContextSlice.reducer