import React from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import getCssVariable from "../../util/functions/getCssVariable";

const Alert = ({ severity, style={}, children }) => {
    const fontFamily = getCssVariable("--dst-font-body-regular");

    const StyledMuiAlert = styled(MuiAlert)(({ theme }) => ({
        fontFamily: fontFamily,
        fontSize: "16px",
    }));

    return (
        <StyledMuiAlert
            severity={severity}
            sx={{ width: "100%", ...style }}
        >
            {children}
        </StyledMuiAlert>
    )
}

export default Alert;