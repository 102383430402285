import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Topbar from "./topbar/Topbar";
import pagePath from "../../util/constants/pagePath";
import "./layout.css";

const Layout = ({ children }) => {
  const { signedIn } = useSelector((state) => state.user);
  const theme = createTheme({});

  return (
    <ThemeProvider theme={theme}>
      <div className="layout">
        <Topbar isAuthenticated={signedIn} />
        {children}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
