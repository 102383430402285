import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import deviceContextReducer from "./slices/deviceContextSlice";

import exampleReducer from "./slices/exampleSlice";
import networkReducer from "./slices/networkSlice";
import userReducer from "./slices/userSlice";

const reducers = combineReducers({
    example: exampleReducer,
    network: networkReducer,
    user: userReducer,   
    device_context: deviceContextReducer,
})

const rootReducer = (state, action) => {
    
    if (action.type === "user/signOut") {
        // persist reducers that should not be reset during signout
        const appScopeReducerNames = ['device_context']
        const newState = {};
        appScopeReducerNames.forEach(name => {
            if (state?.[name]) {
                newState[name] = state[name];
            }
        })
        localStorage.removeItem('persist:root');
        return reducers(newState, action);
    }
    return reducers(state, action);
};

export default persistReducer(
    {
        key: "root",
        storage,
    },
    rootReducer
);