import React ,{ useMemo }from "react";
import "./SignupV2.css";
import { useNavigate } from "react-router-dom";
import pagePath from "../../util/constants/pagePath";
import { useSelector } from "react-redux";
import SignupDisallowed from "./SignupDisallowed";
import SignupForm from "./SignupForm";

const SignupV2 = () => {

    const navigate = useNavigate();
   

    const { platform, os } = useSelector((state) => state.device_context);

    const isSignupAllowed = useMemo(() => {
        // ios app users have to use the browser to sign up

        //removed this  not to allow the user sign up from ios
        
        // if (platform === "mobile_app" && os === "ios") return false;
        return true;
    }, [platform, os])
    

    const handleGoBack = () => {
        navigate(pagePath.home);
    };

    return (
        <>
            <div className="body">
                <div className="upperDiv">
                    <div className="redCircle">
                        <div className="backArrow" onClick={handleGoBack}>
                            &#8592; Back
                        </div>
                        <h2 className="user-info">SignUp</h2>
                    </div>
                </div>
                <div className="lowerDiv">
                 { isSignupAllowed === true ? (
                  <SignupForm/>
                 ):(
                    <SignupDisallowed
                    onCancel={() => navigate(pagePath.home)}
                    continuePath={pagePath.ma_redirect_signup}
                   />
                 ) }
                </div>

            </div>
        </>
    );
};

export default SignupV2;
