import React, { useState, useEffect } from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function Calendar({ onDateChange, onMonthChange, DateCalendarStyle, disableBeforeDate }) {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onDateChange(newDate.format('YYYY-MM-DD'));
  };

  const handleMonthChange = (newMonth) => {
    onMonthChange(newMonth.format('YYYY-MM'));
  };

  const shouldDisableDate = (date) => {
    const disableBefore = dayjs(disableBeforeDate).subtract(1, 'day').startOf('day');
    const disableAfter = dayjs().add(1, 'day').startOf('day');
    return date.isBefore(disableBefore, 'day') || date.isAfter(disableAfter, 'day');
  };

  useEffect(() => {
    const disableBefore = dayjs(disableBeforeDate).subtract(1, 'day');
    if (selectedDate.isBefore(disableBefore, 'day')) {
      setSelectedDate(disableBefore);
    }
  }, [disableBeforeDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        date={selectedDate}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        shouldDisableDate={shouldDisableDate}
        sx={{ ...DateCalendarStyle }}
      />
    </LocalizationProvider>
  );
}

export default Calendar;
