import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faClock,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const BottomNavigation = () => {
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();
  const [playedLink, setPlayedLink] = useState(null);

  useEffect(() => {
    const currentPage = location.pathname;
    setActiveLink(
      ["/dashboard", "/profile", "/past", "/contactus"].includes(currentPage)
        ? currentPage
        : null
    );
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    if (link !== playedLink) {
      setPlayedLink(link);
      playGIF(link);
    }
  };

  const playGIF = (link) => {
    setTimeout(() => {
      setPlayedLink(null);
    }, 1000);
  };

  return (
    <div className="fixed bottom-0 w-full bg-gray-900 text-purple-500 flex justify-around items-center h-16 z-50">
      <Link
        to="/dashboard"
        className={`flex flex-col items-center ${
          activeLink === "/dashboard" ? "!text-purple-500 font-semibold" : "!text-gray-400"
        }`}
        onClick={() => handleLinkClick("/dashboard")}
      >
        <FontAwesomeIcon
          icon={faHome}
          className={`h-6 w-6 mb-1 ${
            activeLink === "/dashboard" ? "!text-purple-500 font-semibold" : "!text-gray-400"
          }`}
        />
        <span className="text-xs font-rale">Home</span>
      </Link>

      <Link
        to="/past"
        className={`flex flex-col items-center ${
          activeLink === "/past" ? "!text-purple-500 font-semibold" : "!text-gray-400"
        }`}
        onClick={() => handleLinkClick("/past")}
      >
        <FontAwesomeIcon
          icon={faClock}
          className={`h-6 w-6 mb-1 ${
            activeLink === "/past" ? "!text-purple-500 font-semibold" : "!text-gray-400"
          }`}
        />
        <span className="text-xs font-rale">Past</span>
      </Link>

      <Link
        to="/contactus"
        className={`flex flex-col items-center ${
          activeLink === "/contactus" ? "!text-purple-500 font-semibold" : "!text-gray-400"
        }`}
        onClick={() => handleLinkClick("/contactus")}
      >
        <FontAwesomeIcon
          icon={faPhone}
          className={`h-6 w-6 mb-1 ${
            activeLink === "/contactus" ? "!text-purple-500 font-semibold" : "!text-gray-400"
          }`}
        />
        <span className="text-xs font-rale">Contact</span>
      </Link>

      <Link
        to="/profile"
        className={`flex flex-col items-center ${
          activeLink === "/profile" ? "!text-purple-500 font-semibold" : "!text-gray-400"
        }`}
        onClick={() => handleLinkClick("/profile")}
      >
        <FontAwesomeIcon
          icon={faUser}
          className={`h-6 w-6 mb-1 ${
            activeLink === "/profile" ? "!text-purple-500 font-semibold" : "!text-gray-400"
          }`}
        />
        <span className="text-xs font-rale">Profile</span>
      </Link>
    </div>
  );
};

export default BottomNavigation;
