import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./ContactUs.css";
import AnimatedLink from "../Animation/AnimatedLink";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import ProgressPage from "../../components/progressPage/ProgressPage";

const ContactUs = () => {
  const [briefText, setBriefText] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [userData, setUserData] = useState({});
  const [concerns, setConcerns] = useState([]);
  const [sortBy, setSortBy] = useState({ column: "", order: "asc" });
  const textareaRef = useRef(null);
  const [triggerError, setTriggerError] = useState(false);

  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
      setBriefText("");
      setSelectedIssue("");
      setDescription("");
    }, 2000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getUserData = await standardBizApiClient.get_userDetails();
        setUserData(getUserData);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchConcerns = async () => {
      try {
        const response = await standardBizApiClient.get_concerns();
        setConcerns(response.concern_result || []);
      } catch (error) {
        console.error("Error occurred while fetching concerns:", error);
        setConcerns([]);
      }
    };
    fetchConcerns();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const concern_status = "pending";
    let issueText = selectedIssue === "Other" ? briefText : selectedIssue;

    try {
      const response = await standardBizApiClient.concern_user_feedback(
        userData.email_address,
        issueText,
        concern_status,
        description
      );
      if (response.status_code === 200) {
        showSuccessMessage(
          "Sent successfully, Our team will reach you out soon!"
        );
        setTimeout(() => {
          setSuccessMessage(null);
          window.location.reload();
        }, 2000);
      } else {
        console.error(
          "Error occurred while saving concern:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [briefText]);

  const handleTextareaChange = (e) => {
    setBriefText(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSort = (column) => {
    const isAsc = sortBy.column === column && sortBy.order === "asc";
    setSortBy({ column, order: isAsc ? "desc" : "asc" });
  };

  const sortedConcerns = [...concerns].sort((a, b) => {
    if (sortBy.column) {
      const orderMultiplier = sortBy.order === "asc" ? 1 : -1;
      if (a.concern[sortBy.column] < b.concern[sortBy.column])
        return -1 * orderMultiplier;
      if (a.concern[sortBy.column] > b.concern[sortBy.column])
        return 1 * orderMultiplier;
      return 0;
    }
    return 0;
  });

  return (
    <div className="contact-us-container">
      {/* Back button using FontAwesomeIcon */}
      <div className="back-button-contact">
        <Link to="/dashboard">
          <FontAwesomeIcon icon={faChevronLeft} className="!text-white !text-3xl" />{" "}
          {/* Replacing the arrow with FontAwesome */}
        </Link>
      </div>
      <div className="contact-us-content">
        <h1 className="contact-us-heading">Contact Us</h1>
        <p>
          We'd love to hear from you! Please use the form below to contact us.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="issueSelect">Select Your Issue:</label>
            <select
              id="issueSelect"
              value={selectedIssue}
              onChange={(e) => setSelectedIssue(e.target.value)}
              required
            >
              <option value="">--Please choose an issue--</option>
              <option value="Login Issue">Login Issue</option>
              <option value="Account Recovery">Account Recovery</option>
              <option value="Billing Question">Billing Question</option>
              <option value="Feature Request">Feature Request</option>
              <option value="Report a Bug">Report a Bug</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {selectedIssue === "Other" && (
            <div className="form-group">
              <label htmlFor="otherIssue">Please Describe Your Issue:</label>
              <textarea
                ref={textareaRef}
                id="otherIssue"
                value={briefText}
                onChange={handleTextareaChange}
                required={selectedIssue === "Other"}
                placeholder="Other issue"
              ></textarea>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Provide additional details or description here."
            ></textarea>
          </div>
          <button className="contactus_button" type="submit">
            Send
          </button>
        </form>
        {successMessage && (
          <div className="fullscreen-popup">
            <div className="popup-content">
              <div className="success-message">{successMessage}</div>
            </div>
          </div>
        )}
        <p className="!text-gray-600">
          If you prefer, you can also reach us directly at{" "}
          <a href="mailto:techsupport@destini.ai">success@destini.ai</a>.
        </p>
        {concerns.length > 0 && (
          <>
            <h2>Previous Concerns</h2>
            <div>
              <TableContainer
                component={Paper}
                className="scrollableTableContainer"
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <TableSortLabel
                          active={sortBy.column === "concern_msg"}
                          direction={
                            sortBy.column === "concern_msg"
                              ? sortBy.order
                              : "asc"
                          }
                          onClick={() => handleSort("concern_msg")}
                        >
                          Message
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="tableHeaderCell">
                        <TableSortLabel
                          active={sortBy.column === "concern_status"}
                          direction={
                            sortBy.column === "concern_status"
                              ? sortBy.order
                              : "asc"
                          }
                          onClick={() => handleSort("concern_status")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="tableHeaderCell">
                        <TableSortLabel
                          active={sortBy.column === "created_dt"}
                          direction={
                            sortBy.column === "created_dt"
                              ? sortBy.order
                              : "asc"
                          }
                          onClick={() => handleSort("created_dt")}
                        >
                          Created Date
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedConcerns.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-size">
                          {item.concern.concern_msg}
                        </TableCell>
                        <TableCell className="font-size">
                          {item.concern.concern_status}
                        </TableCell>
                        <TableCell className="font-size">
                          {new Date(
                            item.concern.created_dt
                          ).toLocaleDateString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default () => (
  <ErrorBoundary fallback={<ProgressPage />}>
    <ContactUs />
  </ErrorBoundary>
);
