import axios from "axios";

class BizApiAxiosClient {
  constructor() {
    this.urlBase = "/business";
  }

  _buildRequestUrl = (path) => {
    // enforce url path pattern with leading slash
    if (path[0] !== "/") path = `/${path}`;
    return this.urlBase + path;
  };

  healthcheck = () => axios.get(this._buildRequestUrl("/healthcheck"));

  account_ping = () => axios.post(this._buildRequestUrl("/account/ping"));

  // generating otp for sign up
  generate_otp_for_signup = (mobile_number) =>
    axios.post(this._buildRequestUrl("/mobile_otp/generate_otp_for_signup"), {
      mobile_number: mobile_number,
    });

  // validating otp for sign up
  validate_otp_for_signup = (mobile_number, otp) =>
    axios.post(this._buildRequestUrl("/mobile_otp/validate_otp_for_signup"), {
      mobile_number: mobile_number,
      otp: otp,
    });

  generate_otp_for_signin = (mobile_number) =>
    axios.post(this._buildRequestUrl("/mobile_otp/generate_otp_for_signin"), {
      mobile_number: mobile_number,
    });

    get_tomorrow_destini = (dasha_dt) =>
    axios.post(this._buildRequestUrl("/user_prediction/get_tomorrow_destini"), {
      dasha_dt: dasha_dt
    });

  validate_otp_for_signin = (mobile_number, otp) =>
    axios.post(this._buildRequestUrl("/mobile_otp/validate_otp_for_signin"), {
      mobile_number: mobile_number,
      otp: otp,
    });
  account_signup = (
    name,
    email_address,
    gender,
    mobile_number,
    birth_dateTime,
    location,

    latitude,
    longitude
  ) =>
    axios.post(this._buildRequestUrl("/account/signup"), {
      name: name,
      email_address: email_address,
      gender: gender,
      mobile_number: mobile_number,
      birth_dt: birth_dateTime,

      location: location,

      latitude: latitude,
      longitude: longitude,
    });

  // generate_otp = (userId) =>
  //   axios.post(this._buildRequestUrl("/account/generate_otp"), {
  //     user_id: userId,
  //   });

  // verify_otp = (userId, otp) =>
  //   axios.post(this._buildRequestUrl("/account/verify_otp"), {
  //     user_id: userId,
  //     otp: otp,
  //   });

  account_signup_confirm = (emailAddress, token) =>
    axios.post(this._buildRequestUrl("/account/signup_confirm"), {
      email_address: emailAddress,
      token: token,
    });

  account_login = (emailAddress, password) =>
    axios.post(this._buildRequestUrl("/account/signin"), {
      email_address: emailAddress,
      password: password,
    });

  account_support_login = (userId, token, session) =>
    axios.post(this._buildRequestUrl("/account/support_signin"), {
      user_id: userId,
      token: token,
      session: session,
    });

  account_signout = () => axios.post(this._buildRequestUrl("/account/signout"));

  account_reset_password_initiate = (emailAddress) =>
    axios.post(this._buildRequestUrl("/account/reset_password_initiate"), {
      email_address: emailAddress,
    });

  account_reset_password = (password, token) =>
    axios.post(this._buildRequestUrl("/account/reset_password"), {
      password: password,
      token: token,
    });

  account_deactivate = () =>
    axios.post(this._buildRequestUrl("/account/deactivate"));

  account_set_payment_method = (paymentMethodId) =>
    axios.post(this._buildRequestUrl("/account/set_payment_method"), {
      payment_method_id: paymentMethodId,
    });


  get_userDetails = () =>
    axios.get(this._buildRequestUrl("/account/get_user_info"), {
    });
  
  update_user_info = (payload) =>
  axios.post(this._buildRequestUrl("/account/update_user_info"), payload
  );

  evolve_user_feedback =( todayPredictionId, todayUserRating, todayAccuracyPercentage, todayUserFeedback ) =>
  axios.post(this._buildRequestUrl("/evolve/post_evolve_detail"),{
    prediction_id: todayPredictionId,
    user_rating: todayUserRating,
    accuracy_percentage: todayAccuracyPercentage,
    user_feedback :  todayUserFeedback
  });

 // add_prediction=(mobile_number , prediction, prediction_date ) =>
  // axios.post(this._buildRequestUrl("user_prediction/add_prediction_info"),
  // { mobile_number: mobile_number,
  //   prediction: prediction,
  //   prediction_date: prediction_date,
  // });
  // get_prediction_info = (mobile_number)=>
  // axios.get(this._buildRequestUrl("/user_prediction/get_prediction_info/" + mobile_number),
  // {mobile_number:mobile_number});
  
  get_destini_data = () =>
  axios.get(this._buildRequestUrl("/user_prediction/get_prediction_info"));

  get_specific_date_destini = (date) =>
  axios.get(this._buildRequestUrl("/user_prediction/get_prediction_info/" + date));

  get_specific_month_destini = (monthYear) =>
  axios.get(this._buildRequestUrl(`/user_prediction/get_prediction_info/${monthYear}`));

  
  update_user_feedback = (todayPredictionID, todayFeedback) =>
  axios.post(this._buildRequestUrl("/user_prediction/update_prediction_info"),{
    prediction_id : todayPredictionID,
    user_feedback : todayFeedback
  });



  get_concerns = () => 
  axios.get(this._buildRequestUrl("/concern/get_concern_detail"));
  
  get_user_feedback=() =>
  axios.get(this._buildRequestUrl("/evolve/get_evolve_detail"));
  
  concern_user_feedback =( email_address, concern_msg, concern_status, description) =>
  axios.post(this._buildRequestUrl("/concern/post_concern_detail"),{
    email_address: email_address,
    concern_msg: concern_msg,
    concern_status: concern_status,
    description : description
  });

  account_reactivate = (mobile_number,name) =>
  axios.post(this._buildRequestUrl("/support/account_reactivation"), {
    mobile_number: mobile_number,
    name:name,

  });

  account_get_payment_method = () =>
    axios.get(this._buildRequestUrl("/account/payment_method"));

  param_stripe_pub_key = () =>
    axios.get(this._buildRequestUrl("/param/stripe_pub_key"));
}

export default BizApiAxiosClient;
