// import "../../src/styles/signup1.css";
import destiny from "../OTP signup/destiny.png";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
// import { useNavigate } from "react-router-dom";
import data from "../data/phonecode.json";
import { useState } from "react";
import "../OTP signup/Success_signup.css";
import { Navigate, useNavigate } from "react-router-dom";
import pagePath from "../../util/constants/pagePath";
import { GoogleLogin } from "react-google-login";
// const [useGoogleLogin, setUseGoogleLogin] = useState(false);
export default function Success_Signup() {
  const [selectedCode, setSelectedCode] = useState("");
  const [mobile_number, setMobile] = useState("");
  const [error, setError] = useState({});
  const [isValidCode, setIsValidCode] = useState(true);
  const numberOfInputs = 4;
  const [otpDigit, setOtp] = useState(Array(numberOfInputs).fill(""));
  const [popup, setPopup] = useState(false);
  const [prev, setPrev] = useState(true);
  const [error1, setError1] = useState({});
  const [button, setButton] = useState(false);
  const userOtp = otpDigit.toString();
  const userOtp1 = userOtp.replace(/\,/g, "");
  const [otpError, setOtpError] = useState(false);
  const [otpError1, setOtpError1] = useState(false);
  // const navigate = useNavigate();
  const [otpNumber, setOtpNumber] = useState(false);
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = [...otpDigit];
    setButton(false);

    if (value.length === 1) {
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      if (index < numberOfInputs - 1) {
        // Move focus to the next input field
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value.length === 0) {
      // Move focus to the previous input field
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };
  const responseGoogle = (response) => {
    console.log(response);

    if (response.error === "popup_closed_by_user") {
      localStorage.setItem("accessToken", response.token);
      // navigate("/dashboard");
    }
  };
  var otpp = false;
  const otpVerify = async (e) => {
    e.preventDefault();
    const handleOtp = () => {
      if (userOtp1 == "") {
        return "Please Enter your Otp";
      } else if (userOtp1.length != 4) {
        return "please Enter your Otp Properly";
      } else {
        return (otpp = true);
      }
    };
    const error = handleOtp();
    setError((prev) => ({
      ...prev,
      otp1: error,
    }));
    if (otpp) {
      const verify = await standardBizApiClient.validate_otp(
        mobile_number,
        userOtp1
      );
      console.log(userOtp);
      console.log(verify);
      if (verify.status === "Your mobile verification is successful") {
        console.log(verify);
        setOtpError1(true);
        setOtpError(false);
        setButton(true);
      } else {
        setOtpError(true);
      }
    }
  };
  const handleCode = (event) => {
    const value = event.target.value;
    setSelectedCode(value);
    setIsValidCode(true);
  };
  const mobileChange = (event) => {
    setMobile(event.target.value);
    const handleMobile = () => {
      if (mobile_number === "") {
        return "Please Enter Your Mobile number";
      } else if (mobile_number.length < 9) {
        return "Mobile number should contain 10 digits";
      } else if (mobile_number.match(namePattern)) {
        return "Please Enter Numbers only";
      } else {
      }
    };

    const error1 = handleMobile();
    setError((prev) => ({
      ...prev,
      mobile: error1,
    }));
  };
  var code = false;
  var mbl = false;
  const namePattern = /[A-Za-z]/;
  const mobilePattern = /[0-9]/;

  const otp = async (e) => {
    e.preventDefault();
    const handleMobile = () => {
      if (mobile_number === "") {
        return "Please Enter Your Mobile number";
      } else if (mobile_number.length < 10) {
        return "Mobile number should contain 10 digits";
      } else if (!mobile_number.match(mobilePattern)) {
        return "Please Enter Numbers only";
      } else {
        return (mbl = true);
      }
    };

    const error1 = handleMobile();
    setError((prev) => ({
      ...prev,
      mobile: error1,
    }));
    if (selectedCode) {
      setIsValidCode(true);
      code = true;
    } else {
      setIsValidCode(false);
    }
    if (code & mbl) {
      // navigate("/userSignup/OTP&verification");
      console.log(1);
      const otp_generate = await standardBizApiClient.generate_otp(
        mobile_number
      );
      console.log(mobile_number);
      console.log(otp_generate);

      setOtpNumber(true);
    }
  };
  const navigate = useNavigate();
  const login = () => {
    navigate(pagePath.loginV2);
  };

  return (
    <div className="mainContainer-Signup">
      <div className="main-Container-sub">
        {/* <div className="sub-logoDiv">
          <img src={destiny} className="descrption"></img>
        </div> */}
        {/* <h2 className="logoHead1">Your Way To Exit Matrix Convention</h2> */}
        {/* <div>
          <p className="logosubHead">An amazing journey chosen by you</p>
        </div> */}
        {/* <div> */}
        {/* <p className="logosubHead">Signup Towards An Amazing Journey Choosen By You</p> */}
        {/* </div> */}

        {/* <div className="phoneLabel">Enter your Phone Number</div> */}
        <div className="line-container">
          <h2 className="Success-message">Success</h2>
          {/* <hr className="line" /> */}
          <p className="congratulations-message-text">Congratulations, your account has been successfully created.</p>
        </div>
        <p className="kindly-login-text">Kindly LogIn</p>

        <button className="login-button" onClick={login}>
            LogIn
          </button>

      </div>
    </div>
  );
}
