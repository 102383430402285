import React, { useMemo } from "react";
import "./SignupV2.css";
import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion"; // Importing Framer Motion
import data from "../data/phonecode.json";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import {
  faPhone,
  faArrowLeft,
  faAlignCenter,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";
import pagePath from "../../util/constants/pagePath";
import mainlogo from "../OTP signup/mainlogo.png";
import { useSelector } from "react-redux";

const SignupForm = () => {
  const [selectedCode, setSelectedCode] = useState("IN");
  const [mobile_number, setMobile] = useState("");
  const [error, setError] = useState({});
  const [isValidCode, setIsValidCode] = useState(true);
  const numberOfInputs = 4;
  const [otpDigit, setOtp] = useState(Array(numberOfInputs).fill(""));
  const [popup, setPopup] = useState(false);
  const [prev, setPrev] = useState(true);
  const [error1, setError1] = useState({});
  const [button, setButton] = useState(false);
  const userOtp = otpDigit.toString();
  const [codeError, setCodeError] = useState("");
  const userOtp1 = userOtp.replace(/\,/g, "");
  const [otpError, setOtpError] = useState(false);
  const [otpError1, setOtpError1] = useState(false);
  const [otpNumber, setOtpNumber] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const navigate = useNavigate();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const firstOtpInputRef = useRef(null);
  const [isOTPnotSent, setIsOTPnotSent] = useState(false);
  const [isMobileInputDisabled, setIsMobileInputDisabled] = useState(false);
  const [isMobileNumberRegistered, setIsMobileNumberRegistered] =
    useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [showReactivationSuccessPopup, setShowReactivationSuccessPopup] =
    useState(false);
  const [reactivationMessage, setReactivationMessage] = useState("");

  const handlePrivacyCheckboxChange = (e) => {
    setAgreeToPrivacyPolicy(e.target.checked);
  };

  useEffect(() => {
    let interval;
    if (timer > 0 && isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && isResendDisabled) {
      setIsResendDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer, isResendDisabled]);

  const closePrivacyModal = () => {
    setPrivacyModalOpen(false);
  };

  const openPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  var mbl = false;
  var code = false;

  const handleLoginClick = () => {
    navigate(pagePath.loginV2);

    console.log("Redirecting to login...");
  };

  const handleReactivation = async () => {
    try {
      setPopup(false);
      console.log("Attempting reactivation with mobile number:", mobile_number);
      const data = await standardBizApiClient.account_reactivate(mobile_number);
      console.log("Reactivation success:", data);
      if (data && data.status_code === 200) {
        // Prepare and display the success message
        const message = `Your account has been reactivated successfully with the number ${mobile_number}. You can login now.`;
        setReactivationMessage(message);
        setShowReactivationSuccessPopup(true);
      } else {
        console.error("Reactivation failed or no data returned:", data);
      }
    } catch (error) {
      console.error("Error during account reactivation:", error);
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = [...otpDigit];

    if (value.length === 1) {
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      if (index < numberOfInputs - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value.length === 0) {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
    if (e.target.id === "agreeCheckbox") {
      setAgreeToPrivacyPolicy(e.target.checked);
      setPrivacyModalOpen(false);
    }
  };
  var otpp = false;
  const otpVerify = async (e) => {
    e.preventDefault();
    setIsVerifyingOTP(true);
    const handleOtp = () => {
      if (userOtp1 === "") {
        return "Please Enter your OTP";
      } else if (userOtp1.length !== 4) {
        return "Please Enter your OTP Properly";
      } else {
        return (otpp = true);
      }
      setSaveEnabled(true);
    };

    const error = handleOtp();
    setError((prev) => ({
      ...prev,
      otp1: error,
    }));

    if (otpp) {
      const verify = await standardBizApiClient.validate_otp(
        mobile_number,
        userOtp1
      );
      if (verify.status_code === 200) {
        setOtpError1(true);
        setOtpError(false);
        if (agreeToPrivacyPolicy) {
          navigate(pagePath.userDetails, { state: { mobile_number } });
        } else {
          setError((prev) => ({
            ...prev,
            privacyPolicy: "Please accept the privacy policy to sign up.",
          }));
        }
      } else {
        setOtpError(true);
      }
    }

    setTimeout(() => {
      setIsVerifyingOTP(false);
    }, 2000);
  };

  const handleCode = (event) => {
    const value = event.target.value;
    setSelectedCode(value);
    if (!value) {
      setCodeError("Please select a mobile code.");
    } else {
      setCodeError("");
    }
  };

  const mobileChange = (event) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/[^0-9]/g, "");

    if (inputValue !== numericInput) {
      setError((prev) => ({
        ...prev,
        mobile: "Please enter numbers only for the mobile number.",
      }));
    } else {
      setMobile(numericInput);
      setError((prev) => ({
        ...prev,
        mobile: "",
      }));
    }

    const handleMobile = () => {
      if (numericInput === "") {
        return "Please enter your mobile number";
      } else if (numericInput.length !== 10) {
        return "Mobile number should contain 10 digits";
      } else {
        return (mbl = true);
      }
    };

    const error1 = handleMobile();
    setError((prev) => ({
      ...prev,
      mobile: error1,
    }));

    setSaveEnabled(numericInput.length === 10);
  };

  const otp = async (e) => {
    e.preventDefault();
    setIsSendingOTP(true);
    const handleMobile = () => {
      if (mobile_number === "") {
        return "Please Enter Your Mobile number";
      } else if (mobile_number.length !== 10) {
        return "Mobile number should contain 10 digits";
      } else {
        return (mbl = true);
      }
    };

    const error1 = handleMobile();
    setError((prev) => ({
      ...prev,
      mobile: error1,
    }));

    if (selectedCode) {
      setIsValidCode(true);
      code = true;
    } else {
      setIsValidCode(false);
    }

    if (code && mbl) {
      setIsMobileNumberRegistered(false);

      const otp_generate = await standardBizApiClient.generate_otp(
        mobile_number
      );
      setTimer(30);
      setIsResendDisabled(true);

      if (otp_generate.code === "MOBILE_NUMBER_ALREADY_EXISTS_AND_VERIFIED") {
        setIsMobileNumberRegistered(true);
      } else if (otp_generate.status === "Your number is already verified") {
        setOtpNumber(false);
        setIsOTPSent(false);
        setIsOTPnotSent(true);
      } else if (otp_generate.code === "ACCOUNT_DEACTIVATED") {
        // Trigger the reactivation flow
        setPopup(true); // Open popup to confirm reactivation
      } else {
        setIsMobileInputDisabled(true);
        setOtpNumber(true);
        setIsOTPSent(true);
        setTimeout(() => {
          firstOtpInputRef.current.focus();
        }, 0);
      }
    }

    setTimeout(() => {
      setIsSendingOTP(false);
    }, 2000);
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] overflow-x-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* LOGO */}
      <motion.img
        src="/dst-logo.svg"
        alt="Logo"
        className="w-28 mb-2"
        initial={{ y: -200 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      />

      {/* MAIN FORM */}
      <motion.div
        className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full text-center"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 80, damping: 10 }}
      >
        <motion.h2
          className="text-2xl font-rale font-bold mb-6 text-black"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Register
        </motion.h2>

        {/* PHONE NUMBER INPUT */}
        <motion.div
          className="flex items-center p-2 mb-4"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 70, damping: 12 }}
        >
          <select
            className="outline-none text-black bg-transparent text-lg"
            value={selectedCode}
            onChange={handleCode}
            style={{
              borderBottom: "1px solid #ccc",
              marginBottom: "-2px",
              paddingBottom: "6px",
              marginRight: "8px",
            }}
          >
            <option value=""></option>
            {data.map((ele, index) => (
              <option key={index} value={ele.code}>
                {ele.code} {ele.dial_code}
              </option>
            ))}
          </select>
          <input
            type="tel"
            className="flex-grow outline-none text-black text-lg bg-transparent"
            placeholder="Phone Number"
            onChange={mobileChange}
            disabled={isMobileInputDisabled}
            style={{
              borderBottom: "1px solid #ccc",
              paddingBottom: "2px",
            }}
          />
        </motion.div>

        {/* MOBILE NUMBER ERROR */}
        <div className="text-red-500 text-xs mb-4">{error.mobile}</div>

        {/* OTP INPUT FIELDS */}
        {otpNumber && (
          <motion.div
            className="flex gap-x-10 justify-center mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {otpDigit.map((value, index) => (
              <motion.input
                key={index}
                id={`otp-input-${index}`}
                className="w-10 h-10 text-center text-black border border-gray-300 rounded-md"
                type="tel"
                onChange={(e) => handleInputChange(e, index)}
                maxLength="1"
                ref={index === 0 ? firstOtpInputRef : null}
                whileFocus={{ scale: 1.2 }}
              />
            ))}
          </motion.div>
        )}

        {/* RESEND OTP TIMER */}
        {isOTPSent && isResendDisabled ? (
          <p className="text font-rale left-0 mb-2 text-black mt-4">
            Not Received your code? Try again in {timer} seconds
          </p>
        ) : (
          isOTPSent && (
            <p className="text font-rale text-left mb-2 mt-4 text-black">
              Not Received your code?{" "}
              <button className="text-blue-500 cursor-pointer" onClick={otp}>
                Resend OTP
              </button>
            </p>
          )
        )}

        {/* PRIVACY POLICY CHECKBOX */}
        <motion.div
          className="flex items-center mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <input
            type="checkbox"
            id="agreeCheckbox"
            checked={agreeToPrivacyPolicy}
            onChange={(e) => handleInputChange(e)}
            className="opacity-0 absolute"
          />
          <motion.div
            className="bg-white border-2 border-gray-300 w-5 h-5 rounded-md flex justify-center items-center cursor-pointer"
            onClick={() => setAgreeToPrivacyPolicy(!agreeToPrivacyPolicy)}
            whileTap={{ scale: 0.8 }}
          >
            {agreeToPrivacyPolicy && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </motion.div>
          <label
            htmlFor="agreeCheckbox"
            className="ml-2 text-xs font-rale w-auto text-black cursor-pointer"
            onClick={() => setAgreeToPrivacyPolicy(!agreeToPrivacyPolicy)}
          >
            I agree to the <span className="text-white">..</span>
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 font-rale text-xs underline"
            >
              privacy policy
            </a>
            <span className="ml-1 text-red-500">*</span>
          </label>
        </motion.div>

        {/* OTP AND VERIFY BUTTON */}
        <motion.button
          className={`w-full py-2 rounded-lg bg-orange-500 text-white font-semibold transition-transform ${
            otpNumber
              ? otpDigit.join("").length === 4 && agreeToPrivacyPolicy
                ? "hover:scale-105"
                : "opacity-50"
              : saveEnabled
              ? "hover:scale-105"
              : "opacity-50"
          }`}
          onClick={otpNumber ? otpVerify : otp}
          disabled={
            !saveEnabled ||
            (otpNumber
              ? otpDigit.join("").length !== 4 || !agreeToPrivacyPolicy
              : isSendingOTP)
          }
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {isSendingOTP || isVerifyingOTP ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : otpNumber ? (
            "Verify OTP"
          ) : (
            "Send OTP"
          )}
        </motion.button>

        <motion.p
          className="text text-black font-rale pt-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          Already Registered?{" "}
          <span
            className="text-blue-500 font-rale cursor-pointer"
            onClick={handleLoginClick}
            whileHover={{ scale: 1.1 }}
          >
            Login Here.
          </span>
        </motion.p>

        <motion.img
          src="/chakra.svg"
          alt="Illustration"
          className="mt-4 w-60 mx-auto"
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 120 }}
        />
      </motion.div>

      {/* REACTIVATION CONFIRMATION POPUP */}
      {popup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-xl font-semibold font-rale text-red-500">
              Your account is deactivated. Would you like to reactivate it?
            </p>
            <div className="mt-4 flex justify-around">
              <button
                className="px-4 py-2 bg-[#9BDC1E] font-rale text-white rounded-lg"
                onClick={handleReactivation}
              >
                Yes, Reactivate
              </button>
              <button
                className="px-4 py-2 font-rale bg-gray-500 text-white rounded-lg"
                onClick={() => setPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* REACTIVATION SUCCESS POPUP */}
      {showReactivationSuccessPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold font-rale text-[#9BDC1E]">
              {reactivationMessage}
            </p>
            <button
              className="mt-4 px-4 py-2 bg-[#52C3E6] text-white rounded-lg"
              onClick={() => {
                setShowReactivationSuccessPopup(false);
                navigate(pagePath.loginV2); // Navigate to login page after reactivation
              }}
            >
              Go to Login
            </button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default SignupForm;
