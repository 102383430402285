import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Notifications } from "react-push-notification";
const AppRouter = () => {
    return (
        <BrowserRouter basename="/">
            <Notifications />
            <AppRoutes />
        </BrowserRouter>
    )
}
export default AppRouter;