import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, primaryRole, setLoginRes } from "../../redux/slices/userSlice";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import data from "../data/phonecode.json";
import pagePath from "../../util/constants/pagePath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion"; // Importing Framer Motion

// Framer motion variants for animations
const containerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 100, damping: 20, duration: 0.8 },
  },
  exit: { opacity: 0, y: 50, transition: { ease: "easeInOut", duration: 0.5 } },
};

const staggerContainerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const inputVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "spring", stiffness: 200, damping: 10 },
  },
};

const buttonVariants = {
  hover: { scale: 1.1, transition: { type: "spring", stiffness: 300 } },
  tap: { scale: 0.95 },
};

const LoginV2 = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mobile_number, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState({});
  const [otpError, setOtpError] = useState(false);
  const [otpError1, setOtpError1] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [timer, setTimer] = useState(30);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+91");
  const numberOfInputs = 4;
  const [otp1, setotp1] = useState(Array(numberOfInputs).fill(""));
  const [isOTPSent, setIsOTPSent] = useState(false);
  const firstOtpInputRef = useRef(null);
  const [isMobileNumberEnabled, setIsMobileNumberEnabled] = useState(true);
  const [errorMatter, setErrorMatter] = useState("");
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (timer > 0 && isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && isResendDisabled) {
      setIsResendDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer, isResendDisabled]);

  const handleSuccessfulLogin = (verify) => {
    dispatch(login());
    dispatch(primaryRole("user"));
    dispatch(setLoginRes(verify.session_id));
    navigate("/dashboard");
  };

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    const onlyNumbers = /^[0-9]*$/;
    if (!onlyNumbers.test(value)) {
      setError((prev) => ({
        ...prev,
        mobile: "Please enter numbers only",
      }));
      setIsButtonActive(false); // Keep button disabled if input contains non-numeric values
    } else {
      setError((prev) => ({
        ...prev,
        mobile: "",
      }));
      setIsButtonActive(value.length === 10); // Activate button only when length is exactly 10 digits
    }
    setMobile(value);
    setErrorMatter(false);
  };

  const otp = async (e) => {
    e.preventDefault();
    setSendOtpLoading(true);

    const handleMobile = () => {
      if (mobile_number === "") {
        return "Please Enter Your Mobile number";
      } else if (mobile_number.length < 10) {
        return "Mobile number should contain 10 digits";
      } else if (!mobile_number.match(/^[0-9]*$/)) {
        return "Please Enter Numbers only";
      } else {
        return true;
      }
    };

    const isValidMobile = handleMobile();
    if (isValidMobile) {
      try {
        const otp_generate = await standardBizApiClient.generate_otp_for_signin(
          mobile_number
        );

        if (otp_generate.status_code === 200) {
          setOtpSent(true);
          setIsMobileNumberEnabled(false);
          setErrorMatter(false);
          setotp1(Array(numberOfInputs).fill("")); // Clear the OTP fields
        } else if (
          otp_generate.status_code === 404 &&
          otp_generate.code === "NO_USER_FOUND"
        ) {
          setErrorMatter("You are new here! Kindly Sign up first.");
        } else {
          setErrorMatter("You are new here! Kindly Sign up first.");
        }

        setTimer(30);
        setIsResendDisabled(true);

        if (otp_generate.status === "SMS sent successfully") {
          setIsOTPSent(true);
          setTimeout(() => {
            firstOtpInputRef.current.focus();
          }, 0);
        }
      } catch (error) {
        setErrorMatter("Server is unavailable. Please try again later.");
      }
    } else {
      setErrorMatter(isValidMobile);
    }
    setSendOtpLoading(false);
  };

  const otpVerify = async (e) => {
    var otp = otp1.toString().replace(/\,/g, "");
    e.preventDefault();
    setVerifyLoading(true);
    const handleOtp = () => {
      if (otp === "") {
        return "Please Enter your Otp";
      } else if (otp.length !== 4) {
        return "Please enter a 4-digit OTP";
      } else {
        return true;
      }
    };
    const error = handleOtp();
    setError((prev) => ({
      ...prev,
      otp2: error,
    }));
    if (error === true) {
      try {
        const verify = await standardBizApiClient.validate_otp_for_signin(
          mobile_number,
          otp
        );
        if (verify.status_code === 200) {
          setOtpError1(true);
          setOtpError(false);
          setOtpVerified(true);
          handleSuccessfulLogin(verify);
        } else if (verify.status_code === 401) {
          setOtpError(true);
          setErrorMatter("Invalid OTP. Please try again.");
        } else {
          setOtpError(true);
        }
      } catch (error) {
        setErrorMatter("Failed to verify OTP. Please try again later.");
      }
    }
    setVerifyLoading(false);
  };

  const handleOTPInputChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = [...otp1];

    if (value === "" && e.nativeEvent.inputType === "deleteContentBackward") {
      updatedOtp[index] = ""; // Clear the current field
      setotp1(updatedOtp);
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus(); // Move to the previous input
      }
    } else if (value.length === 1) {
      updatedOtp[index] = value;
      setotp1(updatedOtp);
      if (index < numberOfInputs - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus(); // Move to the next input
      }
    }
  };

  const handleAnimationEnd = () => {
    setIsOTPSent(false);
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6]"
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="flex justify-center pt-4"
      >
        <img
          src="/dst-logo.svg"
          alt="Logo"
          className="w-28 max-sm:w-36 max-sm:mb-20"
        />
      </motion.div>

      <motion.div
        variants={staggerContainerVariants}
        initial="hidden"
        animate="visible"
        className="bg-white shadow-lg rounded-lg mx-auto p-8 w-full max-w-sm flex flex-col items-center"
      >
        <motion.h2
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2 }}
          className="text-center font-rale text-2xl font-semibold text-gray-800 mb-6"
        >
          Log In
        </motion.h2>

        <form className="space-y-4 w-full">
          <motion.div className="flex flex-col space-y-4">
            <motion.div
              variants={inputVariants}
              className="flex items-center space-x-4"
            >
              <select
                className="w-1/3 outline-none bg-transparent text-gray-800 border-b border-gray-300"
                value={selectedCode}
                onChange={(e) => setSelectedCode(e.target.value)}
              >
                <option value="IN +91">IN +91</option>
                {data.map((ele, index) => (
                  <option key={index} value={ele.code}>
                    {ele.code} {ele.dial_code}
                  </option>
                ))}
              </select>
              <input
                type="tel"
                value={mobile_number}
                onChange={handleMobileInputChange}
                className="flex-grow outline-none pl-2 text-gray-800 border-b border-gray-300"
                placeholder="Phone Number"
                disabled={!isMobileNumberEnabled}
              />
            </motion.div>
            {error.mobile && (
              <motion.p
                variants={inputVariants}
                className="text-red-500 text-xs"
              >
                {error.mobile}
              </motion.p>
            )}

            {otpSent ? (
              <motion.div variants={inputVariants} className="space-y-4">
                <motion.div
                  variants={staggerContainerVariants}
                  className="grid grid-cols-4 gap-2"
                >
                  {otp1.map((value, index) => (
                    <motion.input
                      variants={inputVariants}
                      key={index}
                      id={`otp-input-${index}`}
                      type="tel"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleOTPInputChange(e, index)}
                      className="w-12 h-12 text-center border rounded-md"
                    />
                  ))}
                </motion.div>
                {isResendDisabled ? (
                  <p className="font-rale text-xs text-center">
                    Not Received your code? Try again in {timer} seconds
                  </p>
                ) : (
                  <motion.p className="resendMessage text-center font-rale text-xs">
                    Not Received your code?
                    <motion.button
                      className="resendOTPButton"
                      onClick={otp}
                      disabled={isResendDisabled}
                    >
                      Resend OTP
                    </motion.button>
                  </motion.p>
                )}
                {otpError && (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-red-500 text-xs text-center"
                  >
                    Invalid OTP
                  </motion.p>
                )}
                {otpError1 && (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-green-500 text-xs text-center"
                  >
                    OTP Verified
                  </motion.p>
                )}
                <motion.button
                  variants={buttonVariants}
                  whileHover="hover"
                  whileTap="tap"
                  onClick={otpVerify}
                  className={`w-full py-2 bg-orange-500 text-white rounded-lg ${
                    verifyLoading ? "opacity-50" : ""
                  }`}
                  disabled={verifyLoading}
                >
                  {verifyLoading ? "Verifying..." : "Verify OTP"}
                </motion.button>
              </motion.div>
            ) : (
              <motion.button
                variants={buttonVariants}
                whileHover={isButtonActive ? "hover" : null} // Add hover effect only when active
                whileTap={isButtonActive ? "tap" : null} // Add tap effect only when active
                onClick={otp}
                className={`w-full py-2 bg-orange-500 text-white rounded-lg ${
                  sendOtpLoading || !isButtonActive ? "opacity-50" : ""
                }`}
                disabled={sendOtpLoading || !isButtonActive} // Button disabled if loading or mobile number is incomplete
              >
                {sendOtpLoading ? "Sending OTP..." : "Send OTP"}
              </motion.button>
            )}
            {errorMatter && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 text-center text-[14px]"
              >
                {errorMatter}
              </motion.p>
            )}
          </motion.div>
        </form>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-[14px] font-rale text-gray-600 mt-4"
        >
          Don't have an account?{" "}
          <Link
            to={pagePath.signup}
            className="text-orange-500 font-rale text-[15px]"
          >
            Sign up
          </Link>
        </motion.p>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.8 }}
          className="flex justify-center mt-4"
        >
          <img
            src="/man-tree.svg"
            alt="Bottom Illustration"
            className="w-60 max-sm:w-80"
          />
        </motion.div>

        {isOTPSent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onAnimationEnd={handleAnimationEnd}
            className="otpSentAnimation"
          >
            "OTP Sent to Mobile No."
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default LoginV2;
