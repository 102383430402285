import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./past.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faCalendarAlt,
  faClock,
  faArrowLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Calendar from "../../components/customMui/Calender";
import dayjs from "dayjs";
import AnimatedLink from "../Animation/AnimatedLink";
import { useSwipeable } from "react-swipeable";
import swastika from "../landing_page/swastika.gif";
import { motion } from "framer-motion";

const PastPage = () => {
  const [predictions, setPredictions] = useState([]);
  const [filteredPredictions, setFilteredPredictions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [currentPredictionIndex, setCurrentPredictionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cache, setCache] = useState({});
  const [createdDt, setCreatedDt] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getUserData = await standardBizApiClient.get_userDetails();
        if (getUserData.created_dt) {
          setCreatedDt(getUserData.created_dt);
        } else {
          console.error("created_dt is not defined in the API response");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const initialMonthYear = selectedDate.slice(0, 7);
    if (!cache[initialMonthYear]) {
      fetchAllPredictions(initialMonthYear, selectedDate);
    } else {
      // After predictions are fetched, ensure that we filter them based on the selected date
      filterPredictionsByDate(selectedDate, cache[initialMonthYear]);
    }
  }, [selectedDate]);

  const fetchAllPredictions = async (monthYear, date) => {
    setLoading(true);
    try {
      const get_recent_records = await standardBizApiClient.get_destini_data();
      const fetchedPredictions = get_recent_records?.result || [];

      fetchedPredictions.forEach((prediction) => {
        prediction.prediction.prediction_desc = predictionSplit(
          prediction.prediction.prediction_desc,
          prediction.prediction.master_interpretation
        );
      });

      // Store fetched predictions in cache
      setCache((prevCache) => ({
        ...prevCache,
        [monthYear]: fetchedPredictions,
      }));
      setPredictions(fetchedPredictions);

      // Ensure filtering happens once predictions are fetched
      filterPredictionsByDate(date, fetchedPredictions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching month predictions:", error);
      setError("Error fetching month predictions");
      setLoading(false);
    }
  };

  const handleMonthChange = (monthYear) => {
    if (!cache[monthYear]) {
      fetchAllPredictions(monthYear, selectedDate);
    }
  };

  const filterPredictionsByDate = async (date, monthPredictions) => {
    const filterByDate = (predictions, date) => {
      return predictions.filter((item) => {
        const startDate = dayjs(item.prediction.start_date);
        const endDate = dayjs(item.prediction.end_date);
        const selected = dayjs(date);
        return (
          selected.isSame(startDate, "day") ||
          selected.isSame(endDate, "day") ||
          (selected.isAfter(startDate) && selected.isBefore(endDate))
        );
      });
    };

    let selectedDatePredictions = filterByDate(monthPredictions, date);

    if (selectedDatePredictions.length === 1) {
      setFilteredPredictions(selectedDatePredictions);
    } else {
      try {
        const result = await standardBizApiClient.get_specific_date_destini(
          date
        );
        const resultbyDate = result?.result || [];

        if (Array.isArray(resultbyDate)) {
          resultbyDate.forEach((prediction) => {
            prediction.prediction.prediction_desc = predictionSplit(
              prediction.prediction.prediction_desc,
              prediction.prediction.master_interpretation
            );
          });
        }

        selectedDatePredictions = filterByDate(resultbyDate, date);
        setFilteredPredictions(selectedDatePredictions);
      } catch (error) {
        console.error("Error fetching specific date predictions:", error);
        setError(`Error fetching predictions for ${date}`);
      }
    }

    if (selectedDatePredictions.length === 0) {
      setError(`No predictions found on ${date}`);
    } else {
      setError(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPredictionIndex(0);
    // Refetch predictions to ensure latest data is shown
    const initialMonthYear = date.slice(0, 7);
    if (!cache[initialMonthYear]) {
      fetchAllPredictions(initialMonthYear, date);
    } else {
      filterPredictionsByDate(date, cache[initialMonthYear]);
    }
  };

  const predictionSplit = (prediction_desc, master_interpretation) => {
    const predictionText = prediction_desc.trim() || master_interpretation;
    const lines = predictionText.split("\n");
    const paragraphs = [];
    lines.forEach((line) => {
      const sentences = line.split(". ");
      const sentencesPerParagraph = Math.ceil(sentences.length / 5);
      for (let i = 0; i < sentences.length; i += sentencesPerParagraph) {
        paragraphs.push(
          sentences.slice(i, i + sentencesPerParagraph).join(". ")
        );
      }
    });
    return paragraphs;
  };

  const handleNextPrediction = () => {
    if (currentPredictionIndex < filteredPredictions.length - 1) {
      setCurrentPredictionIndex(currentPredictionIndex + 1);
    }
  };

  const handlePrevPrediction = () => {
    if (currentPredictionIndex > 0) {
      setCurrentPredictionIndex(currentPredictionIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPrediction(),
    onSwipedRight: () => handlePrevPrediction(),
    delta: 10,
    trackTouch: true,
    trackMouse: true,
  });

  const PredictionDetails = () => {
    if (loading) {
      return (
        <div className="prediction-details text-center">
          <motion.div
            className="flex flex-col items-center mb-4 mt-10"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0.6 }}
          >
            <motion.div
              className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full"
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                duration: 1,
                ease: "linear",
              }}
            />
            <p className="text-sm text-white mt-2">
              Hold on, your destiny is on the way ...
            </p>
          </motion.div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="prediction-details">
          <p className="error-message text-red-500 text-center">{error}</p>
        </div>
      );
    }

    if (!filteredPredictions.length) {
      return (
        <div className="prediction-details">
          <p className="error-message text-red-500 text-center">
            No predictions available for this date.
          </p>
        </div>
      );
    }

    const currentItem = filteredPredictions[currentPredictionIndex];
    if (!currentItem || !currentItem.prediction) {
      console.error("Current item or prediction data is undefined.");
      return (
        <div className="text-center text-red-500">
          Error: No valid prediction data available.
        </div>
      );
    }

    const formattedStartDate = dayjs(currentItem.prediction.start_date).format(
      "MMMM D, YYYY h:mm A"
    );
    const formattedEndDate = dayjs(currentItem.prediction.end_date).format(
      "MMMM D, YYYY h:mm A"
    );

    return (
      <div
        {...handlers}
        className="prediction-details p-4 max-w-full lg:max-w-4xl mx-auto"
      >
        <div className="prediction-item bg-white rounded-lg shadow-lg p-4 md:p-6 lg:p-8">
          <div className="prediction-heading text-black text-lg md:text-2xl font-semibold mb-4 flex items-center">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="text-purple-500 mr-2"
            />
            Prediction for {selectedDate}
          </div>
          <p className="prediction-validity-date text-gray-600 text-sm max-sm:text-[13px] md:text-base mb-4">
            <FontAwesomeIcon icon={faClock} className="text-purple-500 mr-2" />
            Valid from <span className="font-medium">
              {formattedStartDate}
            </span>{" "}
            to <span className="font-medium">{formattedEndDate}</span>
          </p>

          <ul className="styled-list list-none space-y-3 text-sm md:text-base text-gray-700">
            {currentItem.prediction.prediction_desc.map(
              (paragraph, index) =>
                paragraph.trim() !== "" && (
                  <li
                    key={index}
                    className="flex items-start break-words leading-relaxed"
                  >
                    <img
                      src="/chakra.svg"
                      alt="bullet"
                      className="mr-2 w-4 h-4 md:w-5 mt-1 md:h-5"
                    />
                    {paragraph}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] min-h-screen text-white">
      <div className="container mx-auto px-4 py-4 md:py-8">
        <h1 className="text-2xl font-bold text-center mb-[-30px] text-white font-rale">
          Select Date
        </h1>
        <div className="flex items-center">
          <Link to="/dashboard">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-white text-4xl w-auto mr-4"
            />
          </Link>
        </div>
        <div className="flex justify-center mt-3">
          {createdDt && (
            <div className="calendar-container bg-white text-black rounded-lg shadow-lg p-2 md:p-4">
              <Calendar
                onDateChange={handleDateChange}
                onMonthChange={handleMonthChange}
                DateCalendarStyle={{
                  borderRadius: "5%",
                  bgcolor: "white",
                  "& .MuiYearPicker-root": {
                    "& .MuiPickersYear-yearButton": {
                      margin: "8px",
                      padding: "12px",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#d1e8ff",
                      },
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#007bff",
                      color: "white",
                    },
                  },
                }}
                disableBeforeDate={createdDt}
              />
            </div>
          )}
        </div>
        <PredictionDetails />
      </div>
    </div>
  );
};

export default PastPage;
