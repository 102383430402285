// handles errors from the client. uses an injected error transformation strategy to format errors accordingly

class ClientErrorDecorator {
  constructor(client, errorStrategy) {
    this.client = client;
    this.errorStrategy = errorStrategy;
  }

  healthcheck = async () => {
    try {
      const response = await this.client.healthcheck();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_ping = async () => {
    try {
      const response = await this.client.account_ping();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_signup = async (
    name,
    email_address,
    gender,
    mobile_number,
    birth_dateTime,
    location,

    latitude,
    longitude
  ) => {
    try {
      const response = await this.client.account_signup(
        name,
        email_address,
        gender,
        mobile_number,
        birth_dateTime,
        location,

        latitude,
        longitude
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };
  get_user_info = async () => {
    try {
      const response = await this.client.get_user_info();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  get_userDetails = async () => {
    try {
      const response = await this.client.get_userDetails();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  get_concerns = async () => {
    try {
        const response = await this.client.get_concerns();
        return response.data;
    } catch(error) {
        const parsedError = this.errorStrategy.parse(error);
        return parsedError;
    }
  };
    
//   add_prediction= async (mobile_number , prediction, prediction_date ) =>{
//   try{
//     const response = await this.client.add_prediction(
//       mobile_number,
//       prediction,
//       prediction_date);
//     console.log(response.data);
//     return response.data;
//   } catch (error) {
//     const parsedError = this.errorStrategy.parse(error);
//     return parsedError;
//   }
// };

// get_prediction_data = async (mobile_number)=>{
//   try{
//     const response = await this.client.get_prediction_info(
//       mobile_number
//     );
//     console.log(response);
//     return response;
//   } catch (error) {
//     const parsedError = this.errorStrategy.parse(error);
//     return parsedError;
//   }
// };



    update_userDetails = async (payload) => {
      try {
        const response = await this.client.update_user_info(payload);
        console.log(response.data);
        return response.data;
      } catch (error) {
        const parsedError = this.errorStrategy.parse(error);
        return parsedError;
      }
    };

  get_destini_data = async () => {
    try {
      const response = await this.client.get_destini_data();
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  get_specific_date_destini = async (date) => {
    try {
      const response = await this.client.get_specific_date_destini(date);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  get_specific_month_destini = async (monthYear) => {
    try {
      const response = await this.client.get_specific_month_destini(monthYear);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };
  
  
  

  get_tomorrow_destini = async (dasha_dt) => {
    try {
      const response = await this.client.get_tomorrow_destini(dasha_dt);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  update_user_feedback = async (todayPredictionID, todayFeedback) => {
    try {
      const response = await this.client.update_user_feedback(todayPredictionID, todayFeedback);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  evolve_user_feedback = async ( todayPredictionId, todayUserRating, todayAccuracyPercentage, todayUserFeedback) => {
    try {
      const response = await this.client.evolve_user_feedback( todayPredictionId, todayUserRating, todayAccuracyPercentage, todayUserFeedback);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  get_user_feedback = async () => {
    try {
      const response = await this.client.get_user_feedback();
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };
  
  generate_otp = async (mobile_number) => {
    try {
      const response = await this.client.generate_otp_for_signup(mobile_number);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };
  
  validate_otp = async (mobile_number, otp) => {
    try {
      const response = await this.client.validate_otp_for_signup(mobile_number, otp);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  generate_otp_for_signin = async (mobile_number) => {
    try {
      const response = await this.client.generate_otp_for_signin(mobile_number);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  validate_otp_for_signin = async (mobile_number, otp) => {
    try {
      const response = await this.client.validate_otp_for_signin(mobile_number, otp);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_signup_confirm = async (emailAddress, token) => {
    try {
      const response = await this.client.account_signup_confirm(
        emailAddress,
        token
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_login = async (emailAddress, password) => {
    try {
      const response = await this.client.account_login(emailAddress, password);

      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_support_login = async (userId, token, session) => {
    try {
      const response = await this.client.account_support_login(
        userId,
        token,
        session
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_signout = async () => {
    try {
      const response = await this.client.account_signout();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_reset_password_initiate = async (emailAddress) => {
    try {
      const response = await this.client.account_reset_password_initiate(
        emailAddress
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_reset_password = async (password, token) => {
    try {
      const response = await this.client.account_reset_password(
        password,
        token
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_deactivate = async () => {
    try {
      const response = await this.client.account_deactivate();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_reactivate = async (data) => {
    try {
      const response = await this.client.account_reactivate(data);
      return response.data;
      console.log(response.data)
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_set_payment_method = async (paymentMethodId) => {
    try {
      const response = await this.client.account_set_payment_method(
        paymentMethodId
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  account_get_payment_method = async () => {
    try {
      const response = await this.client.account_get_payment_method();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_subscribe = async (certificationCode, promoCode = null) => {
    try {
      const response = await this.client.certification_subscribe(
        certificationCode,
        promoCode
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_unsubscribe = async (certificationCode) => {
    try {
      const response = await this.client.certification_unsubscribe(
        certificationCode
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_set_focus = async (certificationCode) => {
    try {
      const response = await this.client.certification_set_focus(
        certificationCode
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_list = async () => {
    try {
      const response = await this.client.certification_list();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_get_market_price = async (
    certificationCode,
    promoCode = null
  ) => {
    try {
      const response = await this.client.certification_get_market_price(
        certificationCode,
        promoCode
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_detail = async (certificationCode) => {
    try {
      const response = await this.client.certification_detail(
        certificationCode
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  certification_subscription_list = async () => {
    try {
      const response = await this.client.certification_subscription_list();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_meta = async () => {
    try {
      const response = await this.client.exam_meta();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_domain_history = async () => {
    try {
      const response = await this.client.exam_domain_history();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_full_history = async () => {
    try {
      const response = await this.client.exam_full_history();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_ping = async () => {
    try {
      const response = await this.client.exam_ping();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_summary = async (examId) => {
    try {
      const response = await this.client.exam_summary(examId);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_create_domain_practice = async (domainId, questionCount) => {
    try {
      const response = await this.client.exam_create_domain_practice(
        domainId,
        questionCount
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_create_full = async () => {
    try {
      const response = await this.client.exam_create_full();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_discard = async () => {
    try {
      const response = await this.client.exam_discard();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_next_question = async () => {
    try {
      const response = await this.client.exam_next_question();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };
  concern_user_feedback = async (email_address, concern_msg, concern_status, description) => {
    try {
      const response = await this.client.concern_user_feedback(email_address, concern_msg, concern_status, description);
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  exam_answer_question = async (selection, examType) => {
    try {
      const response = await this.client.exam_answer_question(
        selection,
        examType
      );
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

  param_stripe_pub_key = async () => {
    try {
      const response = await this.client.param_stripe_pub_key();
      return response.data;
    } catch (error) {
      const parsedError = this.errorStrategy.parse(error);
      return parsedError;
    }
  };

}

export default ClientErrorDecorator;
