import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mainImage2 from "../../components/images/about-us-main-image-top-2.webp";
import missionImage from "../../components/images/about-us-mission.webp";
import visionImage from "../../components/images/about-us-vision.webp";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import ProgressPage from "../../components/progressPage/ProgressPage";

const AboutUs = () => {
  const navigate = useNavigate();

  const dashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] min-h-screen">
      {/* Navigation Link */}
      <div className="absolute top-4 left-4">
        <i
          onClick={dashboard}
          className="fas fa-chevron-left text-white text-2xl cursor-pointer"
        ></i>
      </div>

      {/* Hero Section */}
      <section className="flex flex-col lg:flex-row items-center justify-between px-6 lg:px-24 py-10 lg:py-24">
        <div className="lg:w-1/2 text-center lg:text-left mb-10 lg:mb-0">
          <h1 className="text-4xl max-sm:mt-7 lg:text-6xl max-sm:text-center font-rale font-bold text-white leading-tight mb-6">
            About <br />
            <span className="text-white font-rale max-sm:text-center">
              Destini.ai
            </span>
          </h1>
          <p className="text-lg lg:text-xl max-sm:text-center font-rale text-white mb-8">
            Welcome to destini.ai, where we believe in empowering individuals to
            shape their own destiny. At destini.ai, we're more than just an app
            - we're your partner in navigating life's uncertainties and making
            informed decisions about the future.
          </p>
          <button
            onClick={dashboard}
            className="bg-purple-500 font-rale text-white px-8 py-4 rounded-full text-lg lg:text-xl font-semibold hover:bg-purple-200  transition-all duration-300 ease-in-out"
          >
            Try it Now
          </button>
        </div>
        <div className="lg:w-1/2 flex justify-center lg:justify-end">
          <img
            src={mainImage2}
            alt="About Destini.ai"
            className="rounded-xl shadow-lg w-3/4 lg:w-2/3"
          />
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-10 lg:py-20">
        <div className="container mx-auto flex flex-col lg:flex-row-reverse items-center justify-between px-6 lg:px-24">
          <div className="lg:w-1/2 mb-10 lg:mb-0 lg:pl-12">
            <h2 className="text-3xl max-sm:text-center lg:text-4xl font-bold text-white font-rale mb-6">
              Our Mission
            </h2>
            <p className="text-lg lg:text-xl max-sm:text-center text-white font-rale leading-relaxed">
              Our mission is simple yet profound: to provide you with the tools
              and insights you need to take control of your destiny. We
              understand that life is filled with twists and turns, and we're
              here to help you navigate them with confidence and clarity.
            </p>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <img
              src={missionImage}
              alt="Our Mission"
              className="rounded-xl shadow-lg w-3/4 lg:w-2/3"
            />
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="py-10 lg:py-20">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-24">
          <div className="lg:w-1/2 flex justify-center order-2 lg:order-1">
            <img
              src={visionImage}
              alt="Our Vision"
              className="rounded-xl shadow-lg w-3/4 lg:w-2/3"
            />
          </div>
          <div className="lg:w-1/2 order-1 lg:order-2 mb-10 lg:mb-0 lg:pr-12">
            <h2 className="text-3xl max-sm:text-center lg:text-4xl font-bold text-white font-rale mb-6">
              Our Vision
            </h2>
            <p className="text-lg max-sm:text-center lg:text-xl text-gray-700 font-rale leading-relaxed">
              Destini.ai envisions a future where individuals harness the power
              of artificial intelligence and predictive analytics to navigate
              life's uncertainties with confidence. We aim to empower users with
              personalized insights and data-driven guidance, transforming
              decision-making into a clear and informed process.
            </p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="pt-[2.5rem] pb-[4.5rem] max-sm:mb-20">
        <div className="footer text-center">
          <div className="footer-social-media flex justify-center gap-8 mb-4">
            <a
              href="mailto:techsupport@destini.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <img
                src={"maillogo.png"}
                alt="Mail"
                className="h-9 w-9 text-pink-500" /* Added color */
              />
            </a>
            <a
              href="https://www.facebook.com/destini.cub?mibextid=2JQ9oc"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <i
                className="fab fa-facebook text-blue-600 text-2xl" /* Applied same color and size */
              ></i>
            </a>
            <a
              href="https://x.com/destiniAI?t=6_eoTLfYPnC80tMmSf1smQ&s=09"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <img
                src={"x-icon.png"}
                alt="X"
                className="h-6 w-6 text-pink-500" /* Added color */
              />
            </a>
            <a
              href="https://www.instagram.com/destni.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <img
                src={"insta.png"}
                alt="Instagram"
                className="h-6 w-6 text-pink-500" /* Added color */
              />
            </a>
            <a
              href="https://www.linkedin.com/company/destini-ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <i
                className="fab mb-[-10px] fa-linkedin text-blue-600 text-2xl" /* Applied same color and size */
              ></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default () => (
  <ErrorBoundary fallback={<ProgressPage />}>
    <AboutUs />
  </ErrorBoundary>
);
