const pagePathConstant={
  
    // unauthenticated user paths
  
    healthcheck: "",
    home:"/",
    login: "/loginV2",
    support_login: "/support_login",
    signup_confirm: "/confirm_signup/:token",
    reset_password_initiate: "/reset_password",
    reset_password: "/reset_password/:token",
    unauthenticated_default: "/",
    otp_signup: "/otp_signup",
    signup: "/Signup",
    loginV2: "/login",
    userDetails: "/user_details",
    privacypolicy:"/privacy-policy",

     // authenticated user paths
  
     account: "/account",
     astrology: "/astrology",
     apireport: "/apireport",
     vedicastro: "/vedicastro",
     aboutus:"/aboutus",
     profile:"/Profile",
     landing_page: "/dashboard2.0",
     authenticated_default: "/dashboard",
     success_signup: "/success_signup",
     dashboard: "/dashboard",
     evolve: "/evolve",
     past: "/Past",
     contactUs: "/contactus",
     // do not change base_ma_redirect. it is used by the mobile app to determine when to launch the browser
     base_ma_redirect: "/mobile_app_action/browser_redirect"
  }
  
  const pagePathDynamic = {
    // mobile app action
    //we can hande the account for subscription part when we need it
    // ma_redirect_account: pagePathConstant.base_ma_redirect + pagePathConstant.account,
    ma_redirect_signup: pagePathConstant.base_ma_redirect + pagePathConstant.signupV2,
  }
  
   
  const pagePath = {
    ...pagePathConstant,
    ...pagePathDynamic,
}
  
  export default pagePath;
  