import React, { useState, useEffect } from 'react';
import './ProgressPage.css';
import c2Image from './c2 (1).svg';
import mainlogo from './logo.png';

const ProgressPage = () => {
    const [text, setText] = useState('');
    const fullText = 'Under Maintenance';

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            setText(fullText.slice(0, index));
            index++;
            if (index > fullText.length) {
                clearInterval(intervalId);
            }
        }, 150);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="top-section">
            <div className="progress-page"></div>
            <div className="progress-container">
                <div className="header-container">
                    <img src={mainlogo} alt="Logo" className="bg2" />
                    <h1 className="head">Destini</h1>
                </div>
                <div className="main-image-container">
                    <img src={c2Image} alt="destini" className="bg1"/>
                </div>
                <div className="bottom-section">
    <h1 className="head1">{text}</h1>
    <p className="para star-text">
        We'll be right back—just doing some quick updates!
        <span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span>
    </p>
</div>
            </div>
        </div>
    );
};

export default ProgressPage;    