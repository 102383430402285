import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Alert from "../../components/customMui/Alert";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import getCssVariable from "../../util/functions/getCssVariable";
import "./dashboard.css";

const Dashboard = () => {
  return (
    <PageWrapper className="dashboard" title="Dashboard">
      
    </PageWrapper>
  );
};

export default Dashboard;
