import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    signedIn: false,
    primaryRole: "",
    loginRes: null,
  },
  reducers: {
    login: (state) => {
      state.signedIn = true;
    },
    primaryRole: (state, action) => {
      state.primaryRole = action.payload;
    },
    signOut: (state) => {
      state.signedIn = false;
      state.loginRes = null;
    },
    setLoginRes: (state, action) => {
      state.loginRes = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, signOut, primaryRole, setLoginRes } = userSlice.actions;

export default userSlice.reducer;
