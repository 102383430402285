import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "../../image/Image";
import destiny from "../../images/mainlogo.png";
import { motion } from "framer-motion";
import { handleSignout } from "../../../util/functions/handleSignout";
import {
  FaBars,
  FaEye,
  FaUser,
  FaHistory,
  FaPhone,
  FaSignOutAlt,
} from "react-icons/fa";
// import "./topbar.css";
import profileselectedicons from "../topbar/profile-red-icon.gif";
import pastselectedicons from "../topbar/past-icon-red.gif";
import contactselectedicons from "../topbar/contact-icon.gif";
import BottomNavigation from "../../bottomNavigator/BottomNavigation";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";

const sidebarVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 70,
      damping: 10,
      duration: 0.8,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  closed: {
    x: "100%",
    opacity: 0,
    transition: {
      type: "spring",
      stiffness: 70,
      damping: 15,
      duration: 0.8,
      when: "afterChildren",
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const overlayVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const Topbar = ({ isAuthenticated }) => {
  const [userData, setUserData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const location = useLocation();
  const drawerRef = useRef(null);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      const localData = localStorage.getItem("userData");
      if (localData) {
        setUserData(JSON.parse(localData));
      } else if (isAuthenticated) {
        try {
          const getUserData = await standardBizApiClient.get_userDetails();
          const minimalUserData = {
            name: getUserData.name,
            email_address: getUserData.email_address,
          };
          localStorage.setItem("userData", JSON.stringify(minimalUserData));
          setUserData(minimalUserData);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    };
    loadUserData();
  }, [isAuthenticated]);

  const toggleDrawer = () => {
    if (isDrawerOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsDrawerOpen(false);
        setIsClosing(false);
      }, 300);
    } else {
      setIsDrawerOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsClosing(true);
      setTimeout(() => {
        setIsDrawerOpen(false);
        setIsClosing(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location.pathname]);

  return (
    <div>
      <div className="fixed top-0 right-3 p-4 z-50">
        <div className="flex items-center justify-center">
          <div className="dots-container" onClick={toggleDrawer}>
            <motion.div
              className="flex flex-col space-y-1"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img
                src="/hamburger1.svg"
                className="w-8 h-8 md:w-8 md:h-8 lg:w-8 lg:h-8"
              />
            </motion.div>
          </div>
        </div>
      </div>

      {isDrawerOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={toggleDrawer}
        />
      )}

      <motion.div
        className="fixed right-0 top-0 h-full w-80 bg-gray-900 text-white shadow-lg z-50"
        ref={drawerRef}
        initial="closed"
        animate={isDrawerOpen ? "open" : "closed"}
        variants={sidebarVariants}
      >
        {isAuthenticated ? (
          <>
            <motion.div
              className="bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] p-6"
              variants={itemVariants}
            >
              <div className="flex items-center space-x-4">
                <div className="rounded-full border-2 border-white w-16 h-16 overflow-hidden">
                  <img
                    src={userData.profileImage || "/chakra.svg"}
                    alt="avatar"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <div className="text-lg font-rale font-bold">
                    {userData.name}
                  </div>
                  <div className="text-sm font-rale font-medium">
                    {userData.email_address}
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div variants={itemVariants}>
              <Link
                to="/profile"
                onClick={toggleDrawer}
                className="block py-4 px-6 hover:bg-gray-700"
              >
                <FaUser className="inline-block text-purple-500 mr-3" />
                <span className="text-purple-500 font-rale font-bold">
                  Profile
                </span>
              </Link>
              <hr className="border-gray-700" />
            </motion.div>

            <motion.div variants={itemVariants}>
              <Link
                to="/past"
                onClick={toggleDrawer}
                className="block py-4 px-6 hover:bg-gray-700"
              >
                <FaHistory className="inline-block text-purple-500 mr-3" />
                <span className="text-purple-500 font-rale font-bold">
                  Past
                </span>
              </Link>
              <hr className="border-gray-700" />
            </motion.div>

            <motion.div variants={itemVariants}>
              <Link
                to="/aboutus"
                onClick={toggleDrawer}
                className="block py-4 px-6 hover:bg-gray-700"
              >
                <FaEye className="inline-block text-purple-500 mr-3" />
                <span className="text-purple-500 font-rale font-bold">
                  About Us
                </span>
              </Link>
              <hr className="border-gray-700" />
            </motion.div>

            <motion.div variants={itemVariants}>
              <Link
                to="/contactus"
                onClick={toggleDrawer}
                className="block py-4 px-6 hover:bg-gray-700"
              >
                <FaPhone className="inline-block text-purple-500 mr-3" />
                <span className="text-purple-500 font-rale font-bold">
                  Contact Us
                </span>
              </Link>
              <hr className="border-gray-700" />
            </motion.div>

            <motion.div variants={itemVariants}>
              <button
                onClick={handleSignout}
                className="block py-4 px-4 font-rale text-purple-500 font-bold hover:bg-gray-700 w-full text-left"
              >
                <FaSignOutAlt className="inline-block text-purple-500 mr-3" />
                Sign Out
              </button>
            </motion.div>
          </>
        ) : (
          <>
            <motion.div variants={itemVariants}>
              <Link
                to="/login"
                onClick={toggleDrawer}
                className="block py-4 font-rale font-semibold px-6 hover:bg-gray-700"
              >
                <span className="text-purple-500 font-rale font-bold">
                  Login
                </span>
              </Link>
            </motion.div>
            <hr className="border-gray-700" />
            <motion.div variants={itemVariants}>
              <Link
                to="/signup"
                onClick={toggleDrawer}
                className="block py-4 px-6 font-semibold font-rale hover:bg-gray-700"
              >
                <span className="text-purple-500 font-rale font-bold">
                  Sign Up
                </span>
              </Link>
            </motion.div>
          </>
        )}
      </motion.div>

      {isAuthenticated && isTabletView && <BottomNavigation />}
    </div>
  );
};

export default Topbar;
