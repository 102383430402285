import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pagePath from "../../util/constants/pagePath.js";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  

  return (
    <div className={`body }`}>
      <section>
      <div className="scrollable-content">
                                <p className="popup-sub-heading">1. Introduction</p>
                                <p className="sub-text">Welcome to destini.ai. This Privacy Policy is designed to help you understand how we collect, use, and protect your personal information in compliance with the laws and regulations applicable in India. By using our app, you consent to the practices described in this policy.</p>
                                <p className="popup-sub-heading">2. Information We Collect</p>
                                <p className="sub-text">We collect the following types of information:</p>
                                <p className="sub-text">Personal Information: This may include your name, email address, phone number, and any other information you provide voluntarily.</p>
                                <p className="sub-text">Usage Information: We collect data related to your interactions with our app, such as feature usage, preferences, and device information.</p>
                                <p className="popup-sub-heading">3. How We Use Your Information</p>
                                <p className="sub-text">We use the data we collect for the following purposes:</p>
                                <p className="sub-text">Service Delivery: Your information is used to provide the services and features of our app as per our value proposition.</p>
                                <p className="sub-text">Improvement: We analyze usage data to enhance and optimize our app, making it more valuable to you.</p>
                                <p className="sub-text">Communication: We may use your contact information to send you important updates and information related to our app, as well as to respond to your inquiries.</p>
                                <p className="popup-sub-heading">4. Data Sharing</p>
                                <p className="sub-text">We do not sell or trade your data with third parties without your consent, except in the following situations:</p>
                                <p className="sub-text">Service Providers: We may share data with third-party service providers who assist us in app operation, maintenance, and improvement.</p>
                                <p className="sub-text">Legal Compliance: We may disclose your information in response to a legal request or as required by applicable Indian law, regulation, or government authority.</p>

                                <p className="popup-sub-heading">5. Your Choices and Rights</p>
                                <p className="sub-text">Access and Correction: You have the right to access and correct your personal information held by us.</p>
                                <p className="sub-text">Data Deletion: You can request the deletion of your personal information, subject to applicable legal requirements.</p>
                                <p className="sub-text">Consent Management: You have the option to manage your consent for data collection and processing.</p>

                                <p className="popup-sub-heading">6. Data Security</p>
                                <p className="sub-text">We implement robust security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. We are committed to maintaining data security in accordance with Indian data protection laws.</p>

                                <p className="popup-sub-heading">7. Changes to This Privacy Policy</p>
                                <p className="sub-text">We may update this privacy policy to reflect changes in our app's data handling practices or in response to evolving Indian data protection regulations. We will inform you of significant changes through the app or via email.</p>

                                <p className="popup-sub-heading">8. Contact Us</p>
                                <p className="sub-text">For any questions or concerns about this Privacy Policy or data practices, please contact us at <a href="mailto:techsupport@destini.ai">techsupport@destini.ai</a></p>
                            </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
